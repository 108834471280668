import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { checkout, getPlan } from "@/utils/Services";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { saveUser } from "@/slices/userSlice";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { Price } from "@/utils/Types";
import { SUBSCRIPTION_STATUS } from "@/utils/constants";
import { Button } from "@/components/ui/button";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [plan, setPlan] = useState({} as Price);
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const selectedPlan = useAppSelector((state) => state.plan.plan);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const res = await getPlan({ price_id: searchParams.get("plan")! });

        setPlan(res.data);
      } catch (error) {}
    };

    fetchPlan();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoader(true);

    if (!stripe || !elements) {
      return;
    }

    const handleErrorStripe = (e: any) => {
      let errorMessage =
        "There was an error processing your payment. Please try again in a moment.";
      if (e.type === "validation_error") {
        errorMessage =
          "Validation error: Your payment information is incorrect. Please verify and try again.";
      } else if (e.type === "api_connection_error") {
        errorMessage =
          "Connection error: Unable to connect to Stripe. Please check your internet connection and try again.";
      } else if (e.type === "api_error") {
        errorMessage =
          "Server error: A problem occurred with our payment service. Please try again later.";
      } else if (e.type === "authentication_error") {
        errorMessage =
          "Authentication error: Unable to authenticate your payment method. Please try again or use a different payment method.";
      } else if (e.type === "rate_limit_error") {
        errorMessage =
          "Rate limit error: Too many requests made to the server. Please wait a moment and try again.";
      } else if (e.decline_code === "insufficient_funds") {
        errorMessage = "Card declined due to insufficient funds.";
      }
      toast.error(errorMessage);
    };

    const result = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: `${import.meta.env.VITE_APP_URL}/dashboard`,
      },
    });

    if (result.error) {
      setLoader(false);
      return handleErrorStripe(result.error);
    } else {
      const setupIntentId = result?.setupIntent.id;

      try {
        const res = await checkout({
          setup_intent_id: setupIntentId,
          price_id: plan?.stripe_price_id!,
        });

        if (!res) {
          setLoader(false);
          toast.error("Lost connection. Please try again in a moment.");
          return;
        } else {
          if (
            ![SUBSCRIPTION_STATUS.IN_ACTIVE].includes(
              res.data.subscription_status
            )
          ) {
            dispatch(saveUser(res.data));

            navigate({
              pathname: "/dashboard/notes",
              search: `?${createSearchParams({
                subscription: "true",
              })}`,
            });

            toast.success("Subscribed Successfully");
          } else {
            navigate("/pricing");
          }
        }
      } catch (error) {
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <div className="mx-auto max-w-2xl sm:text-center my-10">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          Plan Subscription
        </h2>
      </div>
      <div className="mx-auto max-w-2xl bg-white dark:bg-gray-950 dark:text-white rounded-lg shadow-lg p-6 mt-7">
        <h2 className="text-xl font-semibold mb-4">Summary</h2>
        <div className="border-t border-b py-4">
          <div className="flex justify-between mb-2">
            <span>Plan</span>
            <span>{plan?.plan_title}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span>Pricing</span>
            <span>
              $
              {plan?.tenure === "month"
                ? plan?.amount?.toFixed(2)
                : (plan?.amount / 12).toFixed(2)}{" "}
              / mo (Billed {plan?.tenure === "month" ? "Monthly" : "Yearly"})
            </span>
          </div>
          <p className="text-sm text-gray-500 italic">
            Your subscription begins today with a 30-day free trial. If you
            decide to stop during the trial period, visit Billing to cancel
            before trial and your card won’t be charged. We can’t issue refunds
            once your card is charged.
          </p>
        </div>

        <div className="flex justify-between mt-4 pt-4">
          <span className="font-semibold text-lg">Total</span>
          <span className="text-lg">${plan?.amount?.toFixed(2)}</span>
        </div>

        <h2 className="text-xl font-semibold mt-10 mb-5">Payment Info</h2>
        <form className="border-t py-5" onSubmit={handleSubmit}>
          <PaymentElement />
          {stripe && (
            <Button
              disabled={loader}
              className="w-full py-2 rounded mt-6 bg-sky-800 hover:bg-sky-600 text-white"
            >
              {loader ? (
                <span className="loader h-5 w-5"></span>
              ) : (
                <span>
                  {selectedPlan?.extra?.subscribed_before
                    ? "Confirm Payment"
                    : "Start Free Trial"}
                </span>
              )}
            </Button>
          )}
        </form>
      </div>
    </>
  );
};

export default CheckoutForm;
