/** @format */

import { create } from "zustand";
import jwtDecode from "jwt-decode";
import { setUser as setSentryUser } from "@sentry/react";
import { logout } from "@/utils/Services";

//TODO: Review and refactor, reconcile with AxiosClient
//TODO: Use secure storage for access token and refresh token

interface TokenPayload {
  username: string;
  accessToken: string | null | undefined;
  refreshToken: string | null;
  email: string | null | undefined;
  first_name: string | null;
  last_name: string | null;
  // add other properties here as needed
}

interface Tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

interface AuthStore {
  setNewTokens: any;
  accessToken: string | null;
  refreshToken: string | null;
  username: string | null;
  isLoggedIn: () => boolean;
  login: (tokens: Tokens) => void;
  logout: () => void;
  logoutWithRedirect: () => void;
  logoutWithoutApi: () => void;
}

function setTokensToLocalStorage(tokens: Tokens): void {
  localStorage.setItem("accessToken", tokens.accessToken!);
  localStorage.setItem("refreshToken", tokens.refreshToken!);
}

function setNewAccessTokenToLocalStorage(token: string): void {
  localStorage.setItem("accessToken", token);
}

function removeTokensFromLocalStorage(): void {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
}

export const useAuthStore = create<AuthStore>()((set, get) => ({
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  isLoggedIn: () => !!get().accessToken,
  username: null,
  setNewTokens: (tokens: Tokens) => {
    setNewAccessTokenToLocalStorage(tokens.accessToken!);
    set((state) => ({
      ...state,
      accessToken: tokens.accessToken,
    }));
  },
  login: (tokens) => {
    setTokensToLocalStorage(tokens);
    const decodedToken = jwtDecode<TokenPayload>(tokens.accessToken!);
    set((state) => ({
      ...state,
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
      username: decodedToken.username,
    }));
    // Set the Sentry user
    setSentryUser({
      email: decodedToken.email !== null ? decodedToken.email : undefined,
      name: decodedToken.first_name + " " + decodedToken.last_name,

      // Add any other user information you want to track
    });
  },
  logout: async () => {
    localStorage.setItem("theme", "light");
    document.querySelector("html")?.classList.remove("dark");

    await logout({ refresh: localStorage.getItem("refreshToken")! });
    removeTokensFromLocalStorage();
    set((state) => ({
      ...state,
      accessToken: null,
      refreshToken: null,
      username: null,
    }));
  },
  logoutWithRedirect: async () => {
    localStorage.setItem("theme", "light");
    document.querySelector("html")?.classList.remove("dark");

    await logout({ refresh: localStorage.getItem("refreshToken")! });
    await removeTokensFromLocalStorage();
    set((state) => ({
      ...state,
      accessToken: null,
      refreshToken: null,
      username: null,
    }));

    window.location.href = "/signin";
  },

  logoutWithoutApi: async () => {
    await removeTokensFromLocalStorage();
    set((state) => ({
      ...state,
      accessToken: null,
      refreshToken: null,
      username: null,
    }));

    window.location.href = "/signin";
  },
}));
