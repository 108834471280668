import { Link, useLocation } from "react-router-dom";
import { LucideIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface NavProps {
  isCollapsed: boolean;
  links: {
    title: string;
    label?: string;
    route: string;
    icon?: LucideIcon;
    variant: "default" | "ghost";
  }[];
}

export function Sidebar({ links, isCollapsed }: NavProps) {
  const location = useLocation();

  return (
    <div
      data-collapsed={isCollapsed}
      className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2"
    >
      <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
        {links.map((link, index) =>
          isCollapsed ? (
            <Tooltip key={index} delayDuration={0}>
              <TooltipTrigger asChild>
                <Link
                  to={link.route}
                  className={cn(
                    buttonVariants({
                      variant: location.pathname.startsWith(link.route)
                        ? "default"
                        : "ghost",
                      size: "icon",
                    }),
                    "h-9 w-9",
                    location.pathname.startsWith(link.route) &&
                      "bg-sky-800 hover:bg-sky-600 text-white"
                  )}
                >
                  {link.icon && <link.icon className="h-4 w-4" />}
                  <span className="sr-only">{link.title}</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right" className="flex items-center gap-4">
                {link.title}
                {link.label && (
                  <span className="ml-auto text-muted-foreground">
                    {link.label}
                  </span>
                )}
              </TooltipContent>
            </Tooltip>
          ) : (
            <Link
              key={index}
              to={link.route}
              className={cn(
                buttonVariants({
                  variant: location.pathname.startsWith(link.route)
                    ? "default"
                    : "ghost",
                  size: "sm",
                }),
                location.pathname.startsWith(link.route) &&
                  "bg-sky-800 hover:bg-sky-600 text-white",
                "justify-start"
              )}
            >
              {link.icon && <link.icon className="mr-2 h-4 w-4" />}
              {link.title}
              {link.label && (
                <span
                  className={cn(
                    "ml-auto",
                    location.pathname.endsWith(link.route) &&
                      "text-background dark:text-white"
                  )}
                >
                  {link.label}
                </span>
              )}
            </Link>
          )
        )}
      </nav>
    </div>
  );
}
