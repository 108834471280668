import { Info } from "@phosphor-icons/react";
const EmptyNotes: React.FC = () => {
  return (
    <div id="top-note-div" className="w-full px-2">
      <div id="note-editor-div" className="border-2 rounded-lg">
        <div className="p-5 flex flex-col justify-center items-center">
          <Info className="mb-3" size={40} />
          <span>No Notes Found</span>
        </div>
      </div>
    </div>
  );
};

export default EmptyNotes;
