import React, { useRef, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { keyBy } from "lodash";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Price, Plan } from "@/utils/Types";
import { saveUser } from "@/slices/userSlice";
import { Button } from "@/components/ui/button";

interface PlanCardProps {
  isMonthly: boolean;
  plan: Plan;
  isPlan: boolean;
}

const includedFeatures = [
  "Unlimited Notes",
  "Unlimited Reports",
  "Unlimited Templates",
];

const PlanCard: React.FC<PlanCardProps> = ({ isMonthly, plan, isPlan }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useRef(false);
  const selectedPlan = useAppSelector((state) => state.plan.plan);

  const pricing: { [key: string]: Price } = keyBy(plan?.linked_plans, "tenure");

  useEffect(() => {
    if (pricing["year"] && pricing["month"]) {
      dispatch(
        saveUser({
          discount:
            100 -
            (pricing["year"].amount / (pricing["month"].amount * 12)) * 100,
        })
      );
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    isLoading.current = true;

    if (isPlan) {
      navigate("/dashboard/settings/billing");
    } else {
      navigate({
        pathname: "/payment",
        search: `?${createSearchParams({
          plan: (isMonthly
            ? pricing["month"].id
            : pricing["year"].id
          ).toString(),
        })}`,
      });
    }
    isLoading.current = false;
  };

  return (
    <div className="mx-auto  max-w-2xl rounded-3xl ring-1 ring-gray-200  lg:mx-0 lg:flex lg:max-w-none">
      <div className="p-8 sm:p-10 lg:flex-auto">
        <h3 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {plan?.title}
        </h3>
        <p className="mt-6 text-base leading-7 text-gray-600 dark:text-white">
          {plan?.description}
        </p>
        <div className="mt-10 flex items-center gap-x-4">
          <h4 className="flex-none text-sm font-semibold leading-6 text-slate-600 dark:text-white">
            What’s included
          </h4>
          <div className="h-px flex-auto bg-gray-100" />
        </div>
        <ul
          role="list"
          className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 dark:text-white sm:grid-cols-2 sm:gap-6"
        >
          {includedFeatures.map((feature) => (
            <li key={feature} className="flex gap-x-3">
              <CheckIcon
                aria-hidden="true"
                className="h-6 w-5 flex-none text-slate-600"
              />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
        {pricing["year"] && pricing["month"] ? (
          <div className="rounded-2xl bg-gray-50 dark:bg-white py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
            <div className="mx-auto max-w-xs px-8">
              <p className="text-base font-semibold text-gray-600 dark:text-black">
                Pay {isMonthly ? "Monthly" : "Yearly"}
              </p>
              <div className="mt-6 flex items-baseline justify-center gap-x-2 dark:text-black">
                <span className="text-4xl font-bold">
                  $
                  {isMonthly
                    ? Math.round(pricing["month"].amount)
                    : Math.round(pricing["year"].amount / 12)}
                  /mo
                </span>
                {!isMonthly && (
                  <span className="text-2xl text-slate-400 line-through">
                    ${Math.round(pricing["month"].amount)}/mo
                  </span>
                )}
              </div>
              <Button
                onClick={handleSubmit}
                disabled={
                  isPlan &&
                  (isMonthly ? pricing["month"].id : pricing["year"].id) ===
                    selectedPlan?.plan_price?.id
                }
                className={
                  "mt-10 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 " +
                  (isPlan &&
                  (isMonthly ? pricing["month"].id : pricing["year"].id) ===
                    selectedPlan?.plan_price?.id
                    ? "bg-gray-300"
                    : "bg-sky-800 hover:bg-sky-600 text-white")
                }
              >
                {isLoading.current ? (
                  <span className="loader h-5 w-5"></span>
                ) : (
                  <span>
                    {!isPlan
                      ? selectedPlan?.extra?.subscribed_before
                        ? "Get access"
                        : "Start 30 Days Trial Now"
                      : isPlan &&
                        (isMonthly
                          ? pricing["month"].id
                          : pricing["year"].id) === selectedPlan?.plan_price?.id
                      ? "Current Plan"
                      : "Change Plan"}
                  </span>
                )}
              </Button>
              <p className="mt-6 text-xs leading-5 text-gray-600 dark:text-black">
                Invoices and receipts available for easy company reimbursement
              </p>
            </div>
          </div>
        ) : (
          <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 font-semibold">
            Pricing Coming Soon
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanCard;
