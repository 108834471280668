import React from "react";
import { Dialog } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useAppSelector } from "@/hooks";

interface PlanSuccessModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const PlanSuccessModal: React.FC<PlanSuccessModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const plan = useAppSelector((state) => state.plan.plan);
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="fixed inset-0 z-50 bg-black bg-opacity-50 overflow-y-auto"
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="inline-block w-full max-w-lg p-6 mt-20 overflow-hidden align-middle transition-all transform bg-white dark:bg-gray-950 dark:text-white shadow-xl rounded-2xl">
          <div className="mt-4">
            <CheckCircleIcon className="w-10 h-10 mx-auto text-green-600" />
            <h2 className="text-lg font-medium leading-6 text-gray-900 my-2">
              Successfully Subscribed
            </h2>
            <p>
              You have been successfully subscribed to{" "}
              <strong>{plan.plan_price?.plan_title}.</strong>
            </p>
          </div>
          <div className="mt-4">
            <button
              onClick={() => setIsOpen(false)}
              className="px-4 py-2 text-sm font-medium text-slate-900 bg-slate-100 border border-transparent rounded-md hover:bg-slate-200"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PlanSuccessModal;
