import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { getCurrentPlan } from "@/utils/Services";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { savePlan } from "@/slices/planSlice";
import CurrentPlan from "./CurrentPlan";
import CancelPlan from "./CancelPlan";

const Billing = () => {
  const dispatch = useAppDispatch();
  const plan = useAppSelector((state) => state.plan.plan);

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const res = await getCurrentPlan();

        dispatch(savePlan(res.data));
      } catch (error) {}
    };

    if (isEmpty(plan.plan_price)) {
      fetchCurrentPlan();
    }
  }, []);
  return (
    <>
      <CurrentPlan />
      <CancelPlan />
    </>
  );
};

export default Billing;
