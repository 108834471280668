import React from "react";
import { Link, Form } from "react-router-dom";
import { passwordReset } from "../utils/Services";
import ktx_logo from "../assets/ktx_logo.png";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

interface RegFormInput {
  email: string;
}

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginTop: "2px",
};

export const ForgotPassword: React.FC = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<RegFormInput>();

  const onSubmit: SubmitHandler<RegFormInput> = async (formData, e) => {
    e?.preventDefault();

    const data = {
      email: formData.email,
    };

    try {
      const res = await passwordReset(data);

      toast.success("Email sent successfuly!.");
    } catch (error: any) {
      toast.error("Some error occurred");
    }
  };

  return (
    <>
      <div
        className="flex items-center justify-center bg-gray-50 dark:bg-gray-950 dark:text-white"
        style={{
          height: "100vh",
        }}
      >
        <div className="w-full bg-white max-w-md shadow-xl rounded-xl p-5 mx-2">
          <div>
            <Link to="/">
              <img
                className="mx-auto h-20 w-auto"
                src={ktx_logo}
                alt="Your Company"
              />
            </Link>
          </div>
          <div>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-slate-800">
              Forgot Password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 dark:text-white">
              Recover your account password
            </p>
          </div>

          <Form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <Input
                type="email"
                id="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: "Enter a valid email",
                  },
                })}
                placeholder="Email"
              />
              <div style={errorStyle}>
                {errors.email && <p>{errors.email.message}</p>}
              </div>

              <p className="text-sm my-3">
                We will email you with info on how to reset your password.
              </p>
            </div>

            <div>
              <Button
                type="submit"
                className="w-full bg-sky-800 hover:bg-sky-600 text-white"
              >
                Reset Password
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
