import React, { useRef, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { saveUser } from "@/slices/userSlice";
import { AccountFormInput } from "@/utils/Types";
import {
  updateUserInfo,
  requestUploadImageUrl,
  sendImageToS3,
} from "@/utils/Services";
import { toast } from "react-toastify";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

const PersonalInformation: React.FC = () => {
  const isLoading = useRef(false);
  const user = useAppSelector((state) => state.user.user);

  const [isUploading, setIsUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    setImageUrl(user?.avatar_url || "");
  }, [user]);

  const errorStyle = {
    color: "red",
    fontSize: "12px",
    marginTop: "2px",
  };

  const values = {
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountFormInput>({ values });

  const onSubmit: SubmitHandler<AccountFormInput> = async (formData, e) => {
    e?.preventDefault();

    const data = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      avatar_url: imageUrl,
    };

    isLoading.current = true;
    try {
      const res = await updateUserInfo(data);

      dispatch(saveUser(res.data));

      toast.success("Personal Information Updated Successfully!");
    } catch (error: any) {
      toast.error("Some error has occured.");
    } finally {
      isLoading.current = false;
    }
  };

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];

    if (!file) return;

    const fileType = file["type"];
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      toast.error("Wrong file format.");
      return;
    }

    if (file.size / 1024 > 1024) {
      toast.error("Max file size limit is 1MB.");
      return;
    }

    setIsUploading(true);

    try {
      const response = (await requestUploadImageUrl()).data;

      await sendImageToS3(response.l.url, response.l.fields, file);

      const image = `${response.l.url}${response.l.fields.key}`;

      setImageUrl(image);
    } catch (error) {
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-600 dark:text-white">
          Personal Information
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Use a permanent address where you can receive mail.
        </p>
      </div>

      <form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full flex items-center gap-x-8">
            <img
              src={
                imageUrl
                  ? imageUrl
                  : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=2662&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              }
              alt=""
              className="h-24 w-24 flex-none rounded-lg bg-gray-300 object-cover"
            />
            <div className="max-w-md">
              <Label>Change avatar</Label>
              {isUploading ? (
                <div className="flex items-center text-sm font-semibold text-gray-700 dark:text-white">
                  Uploading <span className="image-loader"></span>
                </div>
              ) : (
                <Input
                  type="file"
                  onChange={handleFileChange}
                  disabled={isUploading}
                />
              )}
              <p className="mt-2 text-xs leading-5 text-gray-400">
                JPG, GIF or PNG. 1MB max.
              </p>
            </div>
          </div>

          <div className="sm:col-span-3">
            <Label htmlFor="first-name">First name</Label>
            <div className="mt-2">
              <Input
                type="text"
                id="first-name"
                {...register("firstName", {
                  required: "First Name is required",
                })}
                autoComplete="given-name"
              />
              <div style={errorStyle}>
                {errors.firstName && <p>{errors.firstName.message}</p>}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <Label htmlFor="last-name">Last name</Label>
            <div className="mt-2">
              <Input
                type="text"
                {...register("lastName", { required: "Last Name is required" })}
                id="last-name"
                autoComplete="family-name"
              />
              <div style={errorStyle}>
                {errors.lastName && <p>{errors.lastName.message}</p>}
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <Label htmlFor="email">Email address</Label>
            <div className="mt-2">
              <Input
                id="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: "Enter a valid email",
                  },
                })}
                type="email"
                disabled
                autoComplete="email"
              />
              <div style={errorStyle}>
                {errors.email && <p>{errors.email.message}</p>}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <Button
            type="submit"
            disabled={isLoading.current}
            className="flex items-center justify-center h-[40px] w-[80px] rounded-md bg-sky-800 hover:bg-sky-600 text-white"
          >
            {isLoading.current ? (
              <span className="loader h-5 w-5"></span>
            ) : (
              <span>Save</span>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PersonalInformation;
