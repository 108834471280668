import { useEffect, useRef, useState } from "react";
import { ArchiveBoxIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { Outlet, useSearchParams, useParams } from "react-router-dom";
import { Sidebar } from "../components/sidebar/sidebar";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import PlanSuccessModal from "@/components/subscription/PlanSuccessModal";
import Navbar from "../components/navbar/navbar";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { SUBSCRIPTION_STATUS } from "@/utils/constants";
import { useAppSelector } from "@/hooks";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Countdown, { CountdownRendererFn } from "react-countdown";

export default function DashboardLayout() {
  const [isOpen, setIsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useAppSelector((state) => state.user.user);
  const plan = useAppSelector((state) => state.plan.plan);
  const sidebarKey = useRef("sidebar");

  const { height, width } = useWindowDimensions();

  const { noteId = "", folder = "" } = useParams<{
    folder: string;
    noteId: string;
  }>();

  useEffect(() => {
    if (noteId) {
      setIsCollapsed(true);
      sidebarKey.current = "collapsed";
    } else {
      setIsCollapsed(false);
      sidebarKey.current = "sidebar";
    }
  }, [noteId]);

  const renderer: CountdownRendererFn = ({ days }) => {
    return <span> {days} days</span>;
  };

  const handleCloseModal = () => {
    if (searchParams.has("subscription")) {
      searchParams.delete("subscription");
      setSearchParams(searchParams);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (searchParams.get("subscription") === "true") {
      setIsOpen(true);
    }
  }, []);

  return (
    <>
      <Navbar />
      {width >= 768 ? (
        <div className="">
          <TooltipProvider delayDuration={0}>
            <ResizablePanelGroup direction="horizontal" className="h-full">
              <div
                onMouseLeave={() => setIsShow(false)}
                onMouseEnter={() => setIsShow(true)}
              >
                <div
                  key={sidebarKey.current}
                  className={
                    "bg-gray-50 dark:bg-[#37415159] " +
                    (isCollapsed ? "w-[50px]" : "w-[200px]")
                  }
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "100%",
                  }}
                >
                  <Sidebar
                    isCollapsed={isCollapsed}
                    links={[
                      {
                        title: "Notes",
                        route: "/dashboard/notes",
                        icon: PencilSquareIcon,
                        variant: "default",
                      },
                      {
                        title: "Archive",
                        route: "/dashboard/archive",
                        icon: ArchiveBoxIcon,
                        variant: "ghost",
                      },
                      // {
                      //   title: "Templates",
                      //   route: "templates",
                      //   icon: PencilSquareIcon,
                      //   variant: "ghost",
                      // },
                      // {
                      //   title: "Reports",
                      //   route: "reports",
                      //   icon: ChartPieIcon,
                      //   variant: "ghost",
                      // },
                    ]}
                  />
                  {/* <Separator />
                <Sidebar
                isCollapsed={isCollapsed}
                links={[
                  {
                    title: "GAEP",
                    route: "gaep",
                    icon: UserGroupIcon,
                    variant: "ghost",
                    },
                    {
                      title: "HSHS",
                      route: "hshs-sj",
                      icon: UserGroupIcon,
                      variant: "ghost",
                      },
                      ]}
                      /> */}

                  {[SUBSCRIPTION_STATUS.TRIAL].includes(
                    user?.subscription_status!
                  ) && (
                    <div className="mx-2 mt-auto mb-2 rounded-xl border border-slate-500 p-3 text-xs text-center flex flex-col items-center">
                      <InformationCircleIcon className="my-1 h-5 w-5 text-slate-500" />
                      Your Trial Ends in
                      <div className="my-1">
                        <strong>
                          {plan?.trial_till && (
                            <Countdown
                              date={plan?.trial_till}
                              renderer={renderer}
                            />
                          )}
                        </strong>
                      </div>
                    </div>
                  )}
                  {isShow && (
                    <div
                      className={
                        "absolute " +
                        (isCollapsed ? "left-[50px]" : "left-[200px] ")
                      }
                    >
                      <div
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        className="relative cursor-pointer"
                      >
                        <div className="flex items-center justify-center rounded-full w-5 h-5 bg-gray-300 dark:bg-gray-600 absolute -right-2.5 top-1">
                          {isCollapsed ? (
                            <CaretRight size={13} weight="bold" />
                          ) : (
                            <CaretLeft size={13} weight="bold" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-[1px] h-full bg-gray-200 dark:bg-gray-600"></div>

              <ResizablePanel id="panel-2" order={2}>
                <div
                  style={{
                    overflow: "auto",
                    height: "calc(100vh - 65px)",
                  }}
                >
                  <div id="outlet">
                    <Outlet />
                  </div>
                </div>
              </ResizablePanel>
            </ResizablePanelGroup>
          </TooltipProvider>
        </div>
      ) : (
        <div>
          <div id="outlet" className="sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </div>
      )}
      <PlanSuccessModal isOpen={isOpen} setIsOpen={handleCloseModal} />
    </>
  );
}
