/** @format */
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import HomeLanding from "./pages/HomeLanding";
import TermsAndConditions from "./pages/TermsAndConditions";
import Mail from "./pages/DashboardLayout";

import Privacy from "./pages/Privacy";
import Hipaa from "./pages/Hipaa";

import DashboardLayout from "./pages/DashboardLayout";
import NoMatch from "./pages/NoMatch";
import { SignIn } from "./pages/SignIn";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";

import SignUp from "./pages/SignUp";
import EmailConfirmation from "./pages/EmailConfirmation";
import ConfirmationEmailSent from "./pages/ConfirmationEmailSent";
import Account from "@/components/UserSettings/Account/Account";
import Billing from "@/components/UserSettings/Billing/Billing";

import PaymentPlans from "@/components/subscription/PaymentPlans";
import PaymentMethod from "@/components/subscription/PaymentMethod";
import { action as actionSignIn } from "./pages/SignIn";
// import Notes, { notesLoader } from './pages/Notes';
// import Note, { noteLoader } from './pages/Note';
import Notes from "./pages/Notes";
import Note from "./pages/Note";
import Settings from "./pages/Settings";
import Reports from "./pages/Reports";
import { AuthRequired } from "./utils/AuthRequired";
import NotesLayout from "./layouts/NotesLayout";
import EmptyNotes from "./pages/EmptyNotes";
import { getNotes } from "./utils/Services";
import ErrorElement from "./pages/Error";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Initialize Sentry only in production environment
if ((import.meta.env.VITE_ENV as string) === "production") {
  if (!Sentry.getCurrentHub().getClient()) {
    Sentry.init({
      dsn: "https://e9c84c669087ec3729a758f84e1229fd@o251216.ingest.sentry.io/4506478140784640",
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/backend\.kortex\.ai\/api/,
            /^wss:\/\/backend\.kortex\.ai\/ws/,
          ],
        }),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
        new Sentry.Feedback({
          colorScheme: "light",
          formTitle: "Send Feedback",
          namePlaceholder: "",
          emailPlaceholder: "",
          messagePlaceholder: "Please enter your feedback",
          submitButtonLabel: "Submit Feedback",
          showBranding: false,
          buttonLabel: "Send Feedback",
          useSentryUser: {
            email: "email",
            name: "name",
          },
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}

const sentryCreateBrowserRouter =
  import.meta.env.VITE_ENV === "production"
    ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
    : createBrowserRouter;

let router = sentryCreateBrowserRouter(
  createRoutesFromElements([
    <Route path="/" element={<HomeLanding />} />,
    <Route path="/terms" element={<TermsAndConditions />} />,
    <Route path="/mail" element={<Mail />} />,
    <Route path="/privacy" element={<Privacy />} />,
    <Route path="/hipaa" element={<Hipaa />} />,
    <Route
      path="/dashboard"
      element={
        <AuthRequired>
          <DashboardLayout />
        </AuthRequired>
      }
      // loader={notesLoader}
    >
      <Route
        path=":folder"
        id="folder"
        element={<Notes />}
        // @ts-ignore
        // loader={notesLoader}
        errorElement={<ErrorElement />}
      >
        <Route
          path=":noteId"
          element={<Note />}
          // @ts-ignore
          // loader={noteLoader}
          errorElement={<ErrorElement />}
        />
        <Route path="empty-notes" element={<EmptyNotes />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
      <Route path="templates" element={<h2>Templates coming soon!</h2>} />
      <Route path="calendar" element={<h2>Calendar</h2>} />
      <Route path="documents" element={<h2>Documents</h2>} />
      <Route path="reports" element={<Reports />} />
      <Route path="GAEP" element={<h2>GAEP</h2>} />
      <Route path="glendale-adventist" element={<h2>Glendale Adventist</h2>} />
      <Route path="emref" element={<h2>Emref</h2>} />
      <Route path="settings" element={<Settings />}>
        <Route path="account" element={<Account />} />,
        <Route path="billing" element={<Billing />} />,
      </Route>
      ,
      <Route path="*" element={<NoMatch />} />
    </Route>,

    <Route path="signin" action={actionSignIn} element={<SignIn />} />,
    <Route path="forgot-password" element={<ForgotPassword />} />,
    <Route path="reset-password" element={<ResetPassword />} />,

    <Route path="signup" element={<SignUp />} />,
    <Route path="registration">
      <Route path="verify-email/:key" element={<EmailConfirmation />} />,
      <Route path="email-verification" element={<ConfirmationEmailSent />} />,
    </Route>,
    <Route path="pricing" element={<PaymentPlans />} />,
    <Route
      path="payment"
      element={
        <AuthRequired>
          <PaymentMethod />
        </AuthRequired>
      }
    />,
    <Route path="*" element={<NoMatch />} />,
  ])
);

export default function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}
