import React, { ReactNode } from "react";
import { X, Plus, Copy } from "@phosphor-icons/react";
import NoteDropdown from "../components/NoteDropdown";

interface NoteSectionProps {
  title: string;
  children: ReactNode;
  className?: string;
  isAdd?: boolean;
  add?: () => void;
  deleteSection?: () => void;
  canDelete?: boolean;
  handleCopy?(): void;
}

const NoteSection: React.FC<NoteSectionProps> = ({
  title,
  children,
  className = "",
  isAdd,
  add,
  deleteSection,
  canDelete,
  handleCopy,
}) => {
  const id = title.toLowerCase().replace(/ /g, "-");

  const dropdownItems = [
    {
      title: "Remove Section",
      href: "#",
      icon: <X size={20} />,
      onClick: deleteSection,
    },
  ];

  return (
    <div className="relative group">
      <div
        id={`note-section-${id}`}
        className={`px-4 py-2 sm:gap-4 sm:px-6 ${className}`}
      >
        <div
          className={
            "flex items-center font-semibold text-lg mb-2 text-gray-800 dark:text-gray-100"
          }
        >
          <span>{title}</span>
          <div className="ml-auto flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            <button
              onClick={() => handleCopy!()}
              className="top-2 right-2 text-gray-900 bg-gray-200 dark:bg-gray-600 dark:text-gray-300 text-sm px-2 py-1 rounded hover:bg-gray-300 dark:hover:bg-gray-500"
            >
              <Copy size={20} />
            </button>
            {canDelete && (
              <div className="flex items-center justify-center rounded-full">
                <NoteDropdown items={dropdownItems} />
              </div>
            )}
          </div>
        </div>
        <div className="mt-1 text-sm sm:text-xs sm:col-span-4 sm:mt-0 flex flex-col items-center justify-center">
          <div className="w-full">{children}</div>
          {isAdd && (
            <div
              onClick={add}
              className="flex items-center justify-center hover:opacity-50 cursor-pointer"
            >
              <Plus
                size={20}
                className="mr-1 rounded-full text-white p-1 bg-sky-800 h-[20px] w-[20px] ml-auto"
              />

              <span className="text-xs">Add {title}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoteSection;
