import React from "react";
import { Button } from "@/components/ui/button";

interface ConfirmationModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  message?: string;
  subMessage?: string;
  confirmAction?: string;
  cancelAction?: string;
  heading?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  isLoading = false,
  onClose,
  onConfirm,
  heading = "Are You Sure?",
  confirmAction = "Delete",
  cancelAction = "Cancel",
  message = "Are you sure you want to proceed with this action?",
  subMessage = "",
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
      <div className="w-full max-w-lg p-6 bg-white dark:bg-gray-900 dark:text-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-xl font-bold">{heading}</h2>
        <p className="mb-2">{message}</p>
        <p className="mb-5 text-sm text-gray-500">{subMessage}</p>
        <div className="flex justify-end space-x-4">
          <Button
            onClick={onClose}
            className="px-4 py-2 font-bold text-white dark:text-black"
          >
            {cancelAction}
          </Button>
          <Button
            onClick={onConfirm}
            disabled={isLoading}
            className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
          >
            {confirmAction}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
