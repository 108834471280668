/** @format */

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

type MenuItem = {
  title: string;
  href: string;
  icon: any;
  onClick?: () => void;
};

type DropdownProps = {
  items: MenuItem[];
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NoteDropdown({ items }: DropdownProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center text-gray-400">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon
            className="h-5 w-5 text-slate-400 hover:text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-950 dark:text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item) => (
              <Menu.Item key={item.title}>
                {({ active }) => (
                  <a
                    href={item.href}
                    onClick={item.onClick}
                    className={
                      (item.title === "Delete Note"
                        ? "text-red-500 hover:text-red-800 dark:hover:text-red-400"
                        : "hover:text-gray-800 dark:hover:text-gray-100") +
                      " flex items-center block px-4 py-2 text-sm  hover:bg-gray-100 dark:hover:bg-gray-700"
                    }
                  >
                    <span className="mr-2">{item.icon}</span>
                    {item.title}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
