export const SUBSCRIPTION_STATUS = {
  UNPAID: "unpaid",
  IN_ACTIVE: "in_active",
  ACTIVE: "active",
  TRIAL: "trialing",
};

export const NOTE: any = {
  name: "Name",
  age: "Age",
  sex: "Sex",
  gender: "Gender",
  updates: "Updates",
  consults: "Consults",
  snippets: "Snippets",
  allergies: "Allergies",
  condition: "Condition",
  assessment: "Assessment",
  icd10_code: "icd10 code",
  procedures: "Procedures",
  diagnostics: "Diagnostics",
  disposition: "Disposition",
  medications: "Medications",
  critical_care: "Critical Care",
  physical_exam: "Physical Exam",
  family_history: "Family History",
  social_history: "Social History",
  chief_complaint: "Chief Complaint",
  review_of_systems: "Review of Systems",
  past_medical_history: "Past Medical History",
  differential_diagnosis: "Differential Diagnosis",
  history_present_illness: "History of Present Illness",
  medical_decision_making: "Medical Decision Making",
  type: "Type",
  details: "Details",
  note: "Note",
  scratchpad: "Scratchpad",
  admission_status: "Admission Status",
  medical_note: "Medical Note",
  room_num: "Room Number",
  comments: "Comments",
  additional_note: "Additional Note",
};
