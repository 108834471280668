/** @format */

import { AxiosResponse } from "axios";
import { client } from "./AxiosClient";
import { User, ResendEmail, Plan, INote, ISectionConfig } from "./Types";

interface LoginData {
  email: string;
  password: string;
}

interface Note {
  results: INote[];
  count: number;
}

interface UserProfile {
  // Define the properties of a user profile object.
}

export function registration(data: User): Promise<AxiosResponse> {
  return client.post("api/auth/registration/", data, {
    headers: { authorization: false },
  });
}

export function verifyEmail(data: string): Promise<AxiosResponse> {
  return client.get(`api/auth/registration/verify-email/${data}/`);
}

export function getPlan(params: { price_id: string }): Promise<AxiosResponse> {
  return client.get(`api/payments/price/?price_id=${params.price_id}`);
}

export function managePlan(): Promise<AxiosResponse> {
  return client.post(`api/payments/create-customer-portal-session/`);
}

export function cancelPlan(): Promise<AxiosResponse> {
  return client.post(`api/payments/cancel-subscription/`);
}

export function createNewNote(): Promise<AxiosResponse> {
  return client.post(`api/notes/`);
}

export function updateUserInfo(data: User): Promise<AxiosResponse<User>> {
  return client.patch(`api/user-profile/update/`, data);
}

export function updateUserPassword(data: User): Promise<AxiosResponse<User>> {
  return client.patch(`api/user-profile/update-password/`, data);
}

export function logOutOtherSessions(data: User): Promise<AxiosResponse<User>> {
  return client.post(`api/user-profile/logout-other-sessions/`, data);
}

export function deleteAccount(data: {
  password: string | undefined;
}): Promise<AxiosResponse> {
  return client.post(`api/user-profile/delete-account/`, { ...data });
}

export function login({ email, password }: LoginData): Promise<AxiosResponse> {
  return client.post(
    "api/login/",
    { email, password },
    { headers: { authorization: false } }
  );
}

export function getProfile(): Promise<AxiosResponse<UserProfile>> {
  return client.get("/users/profile");
}

export function getPlans(): Promise<AxiosResponse> {
  return client.get("api/payments/details/");
}

export function getCurrentPlan(): Promise<AxiosResponse<Plan>> {
  return client.get("api/payments/user-details/");
}

export function getClientSecret(): Promise<AxiosResponse> {
  return client.post("api/payments/initiate/");
}

export function renewSubscription(): Promise<AxiosResponse> {
  return client.post("api/payments/keep-up-subscription/");
}

export function checkout(data: {
  setup_intent_id: string;
  price_id: string;
}): Promise<AxiosResponse> {
  return client.post("api/payments/checkout-confirm/", data);
}

export function passwordReset(data: { email: string }): Promise<AxiosResponse> {
  return client.post("api/password-reset/", data);
}

export function validatePasswordToken(data: {
  token: string;
}): Promise<AxiosResponse> {
  return client.post("api/password-reset/validate_token/", data);
}

export function logout(data: { refresh: string }): Promise<AxiosResponse> {
  return client.post("api/logout/", data);
}

export function confirmPassword(data: {
  password: string;
  token: string;
}): Promise<AxiosResponse> {
  return client.post("api/password-reset/confirm/", data);
}

export function getUser(): Promise<AxiosResponse<User>> {
  return client.get("api/me");
}

export function resendEmail(): Promise<AxiosResponse<ResendEmail>> {
  return client.get("api/user-profile/resend-email/");
}

export const getNotes = async (endpoint: string, params: {}): Promise<Note> => {
  const url = `/api/${endpoint}`;
  const response = await client.get<Note>(url, { params });
  return response.data;
};

export const getMessages = async (params: {
  note_id: string;
  offset?: number;
  limit?: number;
}) => {
  const url = `/api/copilot/note-messages/`;
  const response = await client.get(url, { params });
  return response.data;
};

export const getNoteById = async (
  endpoint: string,
  noteId: string
): Promise<INote> => {
  const url = `/api/${endpoint}/${noteId}`;
  const response = await client.get<INote>(url);
  return response.data;
};

export const postMessage = async (
  noteId: string,
  data: object
): Promise<INote> => {
  const url = `/api/copilot/message/?note_id=${noteId}`;
  try {
    const response = await client.post<INote>(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const retryMessage = async (
  msgId: string,
  data: object
): Promise<INote> => {
  const url = `/api/copilot/${msgId}/retry/`;
  try {
    const response = await client.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const clearMessages = async (noteId: string): Promise<INote> => {
  const url = `/api/notes/${noteId}/clear-chat/`;
  try {
    const response = await client.patch(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signNoteById = async (
  endpoint: string,
  noteId: string
): Promise<INote> => {
  const url = `/api/${endpoint}/${noteId}/sign/`;
  const response = await client.get<INote>(url);
  return response.data;
};

export const sectionConfigByNoteId = async (
  noteId: string
): Promise<ISectionConfig> => {
  const url = `/api/notes/${noteId}/section-config/`;
  const response = await client.get<ISectionConfig>(url);
  return response.data;
};

export const getArchivedNoteById = async (
  endpoint: string,
  noteId: string,
  single_endpoint: string
): Promise<INote> => {
  const url = `/api/${endpoint}/${noteId}/${single_endpoint}`;
  const response = await client.get<INote>(url);
  return response.data;
};

export const updateNoteFieldById = async (
  endpoint: string,
  noteId: string,
  data: object
): Promise<INote> => {
  const url = `/api/${endpoint}/${noteId}/`;
  try {
    const response = await client.patch<INote>(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateConfigByNoteId = async (
  noteId: string,
  data: object
): Promise<ISectionConfig> => {
  const url = `/api/notes/${noteId}/update-section-config/`;
  try {
    const response = await client.patch<ISectionConfig>(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const archiveNote = async (
  endpoint: string,
  noteId: string
): Promise<INote> => {
  const url = `/api/${endpoint}/${noteId}/archive/`;
  try {
    const response = await client.patch<INote>(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNoteByID = async (
  endpoint: string,
  noteId: string
): Promise<INote> => {
  const url = `/api/${endpoint}/${noteId}`;
  const response = await client.delete<INote>(url);
  return response.data;
};

export function requestUploadImageUrl(data?: User): Promise<AxiosResponse> {
  return client.post("api/image-url/", data, {
    // headers: { authorization: false },
  });
}

export const sendImageToS3 = async (
  url: string,
  params: {
    AWSAccessKeyId: string;
    key: string;
    policy: string;
    signature: string;
  },
  fileToSave: any
) => {
  let res;
  const fd = new FormData();
  fd.append("Content-Type", fileToSave.type);
  fd.append("Cache-Control", "max-age=31536000");
  fd.append("AWSAccessKeyId", params.AWSAccessKeyId);
  fd.append("key", params.key);
  fd.append("policy", params.policy);
  fd.append("signature", params.signature);
  fd.append("file", fileToSave);
  res = await client.post(url, fd, {
    headers: {
      "Content-Type": fileToSave.type,
      authorization: false,
    },
  });
  return res.status;
};
