import React from "react";
import { NavLink } from "react-router-dom";

interface TabsProps {}

const secondaryNavigation = [
  { name: "Account", path: "account", current: true },
  // { name: "Notifications", href: "#", current: false },
  { name: "Billing", path: "billing", current: false },
  // { name: "Teams", href: "#", current: false },
  // { name: "Integrations", href: "#", current: false },
];

const Tabs: React.FC<TabsProps> = () => {
  return (
    <header className="border-b border-gray-300">
      {/* Secondary navigation */}
      <nav className="flex overflow-x-auto py-4">
        <ul
          role="list"
          className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-600 sm:px-6 lg:px-8"
        >
          {secondaryNavigation.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  isActive ? "text-slate-900 dark:text-white" : ""
                }
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Tabs;
