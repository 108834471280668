import React from "react";
import { Outlet } from "react-router-dom";
import Tabs from "../components/UserSettings/Tabs";

const Settings: React.FC = () => {
  return (
    <div className="bg-white dark:bg-gray-950 dark:text-white">
      <Tabs />
      <Outlet />
    </div>
  );
};

export default Settings;
