import React, { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AccountFormInput } from "@/utils/Types";
import { logOutOtherSessions } from "@/utils/Services";
import { toast } from "react-toastify";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

const LogOut: React.FC = () => {
  const isLoading = useRef(false);

  const errorStyle = {
    color: "red",
    fontSize: "12px",
    marginTop: "2px",
  };

  const refreshToken = localStorage.getItem("refreshToken") || null;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AccountFormInput>();

  const onSubmit: SubmitHandler<AccountFormInput> = async (formData, e) => {
    e?.preventDefault();

    const data = {
      password: formData.oldPassword,
      refresh_token: refreshToken,
    };

    isLoading.current = true;

    try {
      await logOutOtherSessions(data);

      reset();

      toast.success("Successfully logged out from all other sessions.");
    } catch (error: any) {
      toast.error(
        "Your password was entered incorrectly. Please enter it again."
      );
    } finally {
      isLoading.current = false;
    }
  };

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-600 dark:text-white">
          Log out other sessions
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Please enter your password to confirm you would like to log out of
          your other sessions across all of your devices.
        </p>
      </div>

      <form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full">
            <Label htmlFor="logout-password">Your password</Label>
            <div className="mt-2">
              <Input
                id="logout-password"
                {...register("oldPassword", {
                  required: "Your password is required",
                })}
                type="password"
                autoComplete="current-password"
              />
              <div style={errorStyle}>
                {errors.oldPassword && <p>{errors.oldPassword.message}</p>}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <Button
            type="submit"
            disabled={isLoading.current}
            className="w-[160px] h-[40px] rounded-md bg-sky-800 hover:bg-sky-600 text-white"
          >
            {isLoading.current ? (
              <div className="loader h-5 w-5"></div>
            ) : (
              <span>Log out other sessions</span>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LogOut;
