import PersonalInformation from "./PersonalInformation";
import ChangePassword from "./ChangePassword";
import LogOut from "./LogOut";
import DeleteAccount from "./DeleteAccount";

const Account = () => {
  return (
    <div className="divide-gray-300">
      <PersonalInformation />
      <ChangePassword />
      <LogOut />
      <DeleteAccount />
    </div>
  );
};

export default Account;
