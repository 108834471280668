import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { cancelPlan, renewSubscription } from "@/utils/Services";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { savePlan } from "@/slices/planSlice";
import { formatDateTime } from "@/utils/UtilFuncs";
import ConfirmationModal from "@/components/ConfirmationModal";
import { Button } from "@/components/ui/button";

const ManagePlan: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const plan = useAppSelector((state) => state.plan.plan);
  const dispatch = useAppDispatch();

  const onRenew = async (e: any) => {
    e?.preventDefault();
    setIsLoading(true);

    try {
      const res = await renewSubscription();

      dispatch(savePlan(res.data));

      toast.success("You have re subscribed successfully!");
    } catch (error: any) {
      toast.error("Some error has occured.");
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (e: any) => {
    e?.preventDefault();

    handleOpenModal();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmAction = async () => {
    setIsLoading(true);

    try {
      const res = await cancelPlan();

      dispatch(savePlan(res.data));
      toast.success("Subscription canceled successfully!");
    } catch (error: any) {
      toast.error("Some error has occured.");
    } finally {
      setIsLoading(false);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-600 dark:text-white">
          Manage Plan
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Manage Your Plan.
        </p>
      </div>

      <div className="md:col-span-2 text-sm flex items-center">
        {!plan.extra?.subscribed_before ||
        (plan.extra?.subscribed_before && isEmpty(plan.plan_price)) ? (
          <div className="flex items-center justify-center">
            You don't have any plan yet.
          </div>
        ) : plan?.cancel_at_period_end ? (
          <div className="ml-auto">
            <div>
              Your subscription will be canceled on{" "}
              {formatDateTime(plan?.cycle_end_date!)}{" "}
            </div>
            <Button
              onClick={onRenew}
              disabled={isLoading || !plan?.plan_price?.active}
              className={
                "ml-auto mt-5 flex items-center justify-center h-[40px] w-[180px] rounded-md bg-sky-800 hover:bg-sky-600 text-white " +
                (plan?.plan_price?.active ? "" : "bg-gray-300")
              }
            >
              {isLoading ? (
                <span className="loader h-5 w-5"></span>
              ) : (
                <span>Renew Subscription</span>
              )}
            </Button>
            {!plan?.plan_price?.active && (
              <p className="text-sm text-gray-500 italic mt-5">
                *We have changed our plans pricing effective from next billing
                cycle. If you wish to continue, please subscribe to new pricing.
              </p>
            )}
          </div>
        ) : (
          <div>
            Want to cancel your subscription?{" "}
            <span onClick={onSubmit} className="text-red-500 cursor-pointer">
              Cancel Membership
            </span>
          </div>
        )}
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        heading="Cancel Subscription"
        message="Are you sure you want to cancel your subscription?"
        subMessage={`Your subscription will be canceled on ${formatDateTime(
          plan?.cycle_end_date!
        )}`}
        isLoading={isLoading}
        onClose={handleCloseModal}
        onConfirm={handleConfirmAction}
        cancelAction="Do Not Cancel"
        confirmAction="Cancel"
      />
    </div>
  );
};

export default ManagePlan;
