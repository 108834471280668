import ktx_logo from "../assets/ktx_logo.png";
import { registration } from "../utils/Services";
import React, { useRef, useEffect } from "react";
import { Form, useNavigate } from "react-router-dom";
import { useAuthStore } from "../utils/UseAuthStore";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL as string;

interface RegFormInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsAndPrivacy: boolean;
}

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginTop: "2px",
};

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn());
  const login = useAuthStore((state) => state.login);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard/notes");
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegFormInput>();

  const password = watch("password", "");

  const userEmail = useRef("");
  const passwordError = useRef("");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "password") {
        passwordError.current = "";
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit: SubmitHandler<RegFormInput> = async (formData, e) => {
    e?.preventDefault();

    const data = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      password1: formData.password,
      password2: formData.confirmPassword,
      terms_accepted: formData.termsAndPrivacy,
    };

    userEmail.current = formData.email;

    try {
      const res = await registration(data);

      if (res.data) {
        login({
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        });

        navigate("/registration/email-verification");
      }

      toast.success(
        "Registration successful!. Please confirm your email to activate your account."
      );
    } catch (error: any) {
      if (error?.response?.data?.non_field_errors) {
        passwordError.current = error?.response?.data?.non_field_errors[0];
      } else if (error?.response?.data?.password1) {
        passwordError.current = error?.response?.data?.password1[0];
      }
      toast.error(passwordError.current);
    }
  };

  return (
    <div
      className="flex items-center justify-center bg-gray-50 dark:bg-gray-950 dark:text-white"
      style={{
        height: "100vh",
      }}
    >
      <div className="w-full max-w-2xl mx-2 p-5 bg-white dark:bg-gray-950 dark:text-white shadow-xl rounded-xl">
        <a href={WEBSITE_URL}>
          <img
            className="mx-auto h-20 w-auto"
            src={ktx_logo}
            alt="Your Company"
          />
        </a>

        <h2 className="text-center text-3xl font-bold tracking-tight text-slate-800 mb-4">
          Get Started with Kortex.ai
        </h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: "Enter a valid email",
                },
              })}
              className="mt-1"
              placeholder="Email"
            />
            <div style={errorStyle}>
              {errors.email && <p>{errors.email.message}</p>}
            </div>
          </div>
          <div className="sm:flex sm:space-x-2">
            <div className="mb-4 sm:w-full">
              <Label htmlFor="firstname">First Name</Label>
              <Input
                type="text"
                id="firstname"
                {...register("firstName", {
                  required: "First Name is required",
                })}
                className="mt-1"
                placeholder="First Name"
              />
              <div style={errorStyle}>
                {errors.firstName && <p>{errors.firstName.message}</p>}
              </div>
            </div>
            <div className="mb-4 w-full">
              <Label htmlFor="lastname">Last Name</Label>
              <Input
                type="text"
                id="lastname"
                {...register("lastName", { required: "Last Name is required" })}
                className="mt-1"
                placeholder="Last Name"
              />
              <div style={errorStyle}>
                {errors.lastName && <p>{errors.lastName.message}</p>}
              </div>
            </div>
          </div>
          <div className="sm:flex sm:space-x-2">
            <div className="mb-4 w-full">
              <Label htmlFor="password">Password</Label>
              <Input
                type="password"
                id="password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })}
                className="mt-1"
                placeholder="Password"
              />
              <div style={errorStyle}>
                {passwordError.current ||
                  (errors.password && <p>{errors.password.message}</p>)}
              </div>
            </div>
            <div className="mb-4 sm:w-full">
              <Label htmlFor="confirmpassword">Confirm Password</Label>
              <Input
                type="password"
                id="confirmpassword"
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
                className="mt-1"
                placeholder="Confirm Password"
              />
              <div style={errorStyle}>
                {errors.confirmPassword && (
                  <p>{errors.confirmPassword.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="mb-4">
            <Label className="inline-flex items-center">
              <input
                type="checkbox"
                {...register("termsAndPrivacy", {
                  required: "You must accept the terms and privacy policy",
                })}
                className="form-checkbox focus:outline-none focus-visible:outline-none"
              />
              <span className="ml-2">
                I agree to the{" "}
                <Link
                  to="/terms"
                  className="text-slate-600 hover:text-slate-500 font-semibold ml-1"
                >
                  terms & conditions
                </Link>{" "}
                and
                <Link
                  to="/privacy"
                  className="text-slate-600 hover:text-slate-500 font-semibold ml-1"
                >
                  privacy policy
                </Link>
              </span>
            </Label>
            <div className="mx-5 flex items-center text-xs">
              <div>
                <InformationCircleIcon className="my-3 mr-2 h-6 w-6 text-gray-400" />
              </div>
              <span>
                Our app is HIPAA compliant, ensuring your data privacy and
                security.
                <Link
                  to="/hipaa"
                  className="text-slate-600 hover:text-slate-500 font-semibold ml-1"
                >
                  Learn More
                </Link>
              </span>
            </div>
            <div style={errorStyle}>
              {errors.termsAndPrivacy && (
                <p>{errors.termsAndPrivacy.message}</p>
              )}
            </div>
          </div>

          <Button
            type="submit"
            className="w-full bg-sky-800 hover:bg-sky-600 text-white"
          >
            Start Your Free Trial
          </Button>
          <div className="flex items-center my-3">
            Already have an account?
            <Link
              to="/signin"
              className="text-slate-600 hover:text-slate-500 font-semibold ml-1"
            >
              Log in
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
