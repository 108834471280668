import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { Link, Form, useActionData, useNavigate } from "react-router-dom";
import { useAuthStore } from "../utils/UseAuthStore";
import { login, registration } from "../utils/Services";
import ktx_logo from "../assets/ktx_logo.png";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL as string;

interface Tokens {
  accessToken: string;
  refreshToken: string;
}

interface ActionData {
  error: {
    response: {
      status: number;
    };
  };
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

interface ActionResponse {
  tokens: Tokens | null;
  error: string | null;
}

export const action = async ({ request }: any): Promise<ActionResponse> => {
  try {
    const formData = await request.formData();

    const type = formData.get("type");
    const email = formData.get("email");
    const password = formData.get("password");
    const response =
      type === "register"
        ? await registration({ email, password })
        : await login({ email, password });
    let { access, refresh } = response.data;
    let accessToken = access;
    let refreshToken = refresh;
    return { tokens: { accessToken, refreshToken }, error: null };
  } catch (error: any) {
    return {
      error: error,
      tokens: null,
    };
  }
};

export const SignIn: React.FC = () => {
  const actionData = useActionData() as ActionData;
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn());
  const login = useAuthStore((state) => state.login);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard/notes");
    } else if (actionData?.tokens) {
      login(actionData.tokens);
      navigate("/dashboard/notes");
    }
    setIsLoading(false);
  }, [isLoggedIn, actionData, login, navigate]);

  return (
    <>
      <div
        className="flex items-center justify-center bg-gray-50 dark:bg-gray-950 dark:text-white"
        style={{
          height: "100vh",
        }}
      >
        <div className="w-full bg-white max-w-md shadow-xl rounded-xl p-5 mx-2">
          <a href={WEBSITE_URL}>
            <img
              className="mx-auto h-20 w-auto"
              src={ktx_logo}
              alt="Your Company"
            />
          </a>
          <h2 className="text-center text-3xl font-bold tracking-tight text-gray-800 dark:text-white">
            Sign in to your account
          </h2>

          <Form className="mt-10 space-y-6" method="post">
            <div className="space-y-6">
              <div>
                <Label className="block text-sm/6 font-medium text-gray-900">
                  Email address
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="text"
                  placeholder="Email"
                  autoFocus
                  required
                  className="mt-2 block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-600 sm:text-sm/6"
                />
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <Label
                    htmlFor="password"
                    className="block text-sm/6 font-medium text-gray-900"
                  >
                    Password
                  </Label>
                  <div className="text-sm">
                    <Link
                      to="/forgot-password"
                      className="font-medium text-gray-600 hover:text-gray-500"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="mt-2 block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-600 sm:text-sm/6"
                  placeholder="Password"
                />
              </div>
            </div>

            {actionData?.error && (
              <div className="max-w-md mx-auto mt-2">
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-1 rounded relative"
                  role="alert"
                >
                  <span className="text-sm">
                    {actionData?.error?.response?.status == 429
                      ? "Too many failed login attempts. Please try again later."
                      : "Invalid Email or Password!"}
                  </span>
                </div>
              </div>
            )}

            <div>
              <Button
                disabled={isLoading}
                onClick={() =>
                  setTimeout(() => {
                    setIsLoading(true);
                  })
                }
                type="submit"
                className="group relative flex w-full bg-sky-800 hover:bg-sky-600 text-white"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-white group-hover:text-white"
                    aria-hidden="true"
                  />
                </span>
                {isLoading ? (
                  <span className="loader h-5 w-5"></span>
                ) : (
                  <span>Sign in</span>
                )}
              </Button>
            </div>
          </Form>
          <p className="mt-10 text-center text-sm/6 text-gray-500">
            Don't have an account?{" "}
            <Link
              to="/signup"
              reloadDocument
              className="font-medium text-gray-600 hover:text-gray-500 "
            >
              Start your free trial
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};
