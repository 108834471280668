import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import planSlice from "./slices/planSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    plan: planSlice,
  },
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
