import React, { useRef, useEffect, useState } from "react";
import { Link, Form, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmPassword, validatePasswordToken } from "../utils/Services";
import ktx_logo from "../assets/ktx_logo.png";
import { SubmitHandler, useForm } from "react-hook-form";
import { XCircleIcon } from "@heroicons/react/20/solid";

interface RegFormInput {
  password: string;
  confirmPassword: string;
}

const errorStyle = {
  color: "red",
  fontSize: "12px",
};

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegFormInput>();

  const password = watch("password", "");

  const passwordError = useRef("");

  useEffect(() => {
    const validateToken = async () => {
      try {
        const res = await validatePasswordToken({ token: token! });

        setConfirmed(true);
      } catch (error) {
        setConfirmed(false);
      }
    };

    validateToken();
  }, [token]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "password") {
        passwordError.current = "";
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit: SubmitHandler<RegFormInput> = async (formData, e) => {
    e?.preventDefault();

    const data = {
      password: formData.password,
      token: token!,
    };

    try {
      await confirmPassword(data);

      navigate("/dashboard/notes");
    } catch (error: any) {
      if (error?.response?.data?.non_field_errors) {
        passwordError.current = error?.response?.data?.non_field_errors[0];
      } else if (error?.response?.data?.password) {
        passwordError.current = error?.response?.data?.password[0];
      }
      toast.error(passwordError.current);
    }
  };

  if (!confirmed) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center w-full">
        <div className="bg-white dark:bg-gray-950 dark:text-white p-6 rounded-lg shadow-lg text-center lg:w-2/5 md:w-3/5 sm:w-full">
          <XCircleIcon className="w-10 h-10 mx-auto text-red-600" />

          <h1 className={"my-3 text-2xl font-bold text-red-600"}>
            Reset Password Failed
          </h1>
          <p className="my-4 text-gray-600 dark:text-white">
            The email confirmation key is invalid.
          </p>
          <Link
            to="/"
            className="mt-6 px-4 py-2 bg-slate-500 text-white rounded-lg hover:bg-slate-800"
          >
            Go Back
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div
          className="flex flex-col items-center justify-center bg-white dark:bg-gray-950 dark:text-white"
          style={{
            height: "100vh",
          }}
        >
          <div>
            <Link to="/">
              <img
                className="mx-auto h-20 w-auto"
                src={ktx_logo}
                alt="Your Company"
              />
            </Link>
          </div>
          <div className="w-full max-w-md shadow-xl rounded-xl p-5">
            <div>
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-slate-800">
                Reset Password
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600 dark:text-white">
                Recover your account password
              </p>
            </div>

            <Form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label htmlFor="password">New Password</label>
                <input
                  type="password"
                  id="password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters",
                    },
                  })}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                  placeholder="Password"
                />
                <div style={errorStyle}>
                  {passwordError.current ||
                    (errors.password && <p>{errors.password.message}</p>)}
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="confirmpassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmpassword"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === password || "Passwords do not match",
                  })}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                  placeholder="Confirm Password"
                />
                <div style={errorStyle}>
                  {errors.confirmPassword && (
                    <p>{errors.confirmPassword.message}</p>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md bg-slate-500 px-3 py-2 text-sm font-semibold text-white hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                >
                  Reset Password
                </button>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
};

export default ResetPassword;
