/** @format */

import axios from "axios";
import { useAuthStore } from "./UseAuthStore";

//TODO: Change to env variables
// const REFRESH_TOKEN_URL = 'https://backend.kortex.ai/api/login/refresh/';
// const BASE_URL = 'https://backend.kortex.ai/';
const REFRESH_TOKEN_URL = import.meta.env.VITE_REFRESH_TOKEN_URL as string;
const BASE_URL = import.meta.env.VITE_BASE_URL as string;

let failedQueue: {
  resolve: (value?: unknown) => {};
  reject: (error: any) => void;
}[] = [];
let isRefreshing = false;

const processQueue = (error?: any) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

export const getCurrentAccessToken = () =>
  useAuthStore.getState().accessToken || null;

export const getCurrentRefreshToken = () =>
  useAuthStore.getState().refreshToken || null;

export const setNewAccessToken = (tokens: object) => {
  useAuthStore.getState().setNewTokens(tokens);
};

const logout = async () => {
  useAuthStore.getState().logoutWithoutApi();
};

export const client = (() => {
  const options = {
    baseURL: BASE_URL,
    timeout: 300000,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const client = axios.create(options);

  client.interceptors.request.use(
    (config) => {
      const token = getCurrentAccessToken();
      if (token && config.headers.authorization !== false) {
        config.headers.Authorization = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = { ...error.config, _retry: false };
      const refreshToken = getCurrentRefreshToken();
      const handleError = async (error: any) => {
        processQueue(error);
        await logout();
      };

      if (
        refreshToken &&
        error.response?.status === 401 &&
        error.response.data.code === "token_not_valid" &&
        originalRequest?.url !== REFRESH_TOKEN_URL &&
        !originalRequest._retry
      ) {
        if (isRefreshing) {
          try {
            await new Promise((_, reject) => {
              failedQueue.push({ resolve: () => ({}), reject });
            });
            return await client(originalRequest);
          } catch (err) {
            return await Promise.reject(err);
          }
        }
        isRefreshing = true;
        originalRequest._retry = true;

        return client
          .post(
            REFRESH_TOKEN_URL,
            { refresh: refreshToken },
            {
              headers: { authorization: false },
            }
          )
          .then((res) => {
            setNewAccessToken({
              accessToken: res.data.access,
            });
            processQueue(null);
            return client(originalRequest);
          }, handleError)
          .finally(() => {
            isRefreshing = false;
          });
      }

      if (
        error.response?.status === 401 &&
        error.response?.data?.message === "TokenExpiredError"
      ) {
        return handleError(error);
      }

      if (
        error.response?.status === 401 &&
        !["/signin"].includes(window.location.pathname)
      ) {
        window.location.href = "/signin";
      }

      return Promise.reject(error);
    }
  );

  return client;
})();
