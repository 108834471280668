import React, { useState } from "react";
import { getPlans, getCurrentPlan } from "@/utils/Services";
import { useEffect } from "react";
import PlanCard from "./PlanCard";
import { useAuthStore } from "../../utils/UseAuthStore";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { removeUser } from "@/slices/userSlice";
import { savePlan, removePlan } from "@/slices/planSlice";
import { isEmpty } from "lodash";
import { SUBSCRIPTION_STATUS } from "@/utils/constants";
import HeaderHome from "@/components/HeaderHome";
import FooterHome from "@/components/FooterHome";
import { Plan } from "@/utils/Types";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import useNoteStore from "@/utils/NoteStore";

const PaymentPlans: React.FC = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [isPlan, setIsPlan] = useState(false);
  const [plans, setPlans] = useState([]);

  const dispatch = useAppDispatch();
  const logout = useAuthStore((state) => state.logoutWithRedirect);
  const token = useAuthStore((state) => state.accessToken);
  const plan = useAppSelector((state) => state.plan.plan);
  const user = useAppSelector((state) => state.user.user);
  const { setNotes } = useNoteStore();

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      document.querySelector("html")?.classList.add("dark");
    } else {
      document.querySelector("html")?.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const res = await getPlans();

        setPlans(res.data);
      } catch (error) {}
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const res = await getCurrentPlan();

        dispatch(savePlan(res.data));
      } catch (error) {}
    };

    if (token && isEmpty(plan)) {
      fetchCurrentPlan();
    }

    if (
      token &&
      !isEmpty(plan) &&
      ![SUBSCRIPTION_STATUS.IN_ACTIVE].includes(plan.status!)
    ) {
      if (plan?.plan_price?.tenure === "month") {
        setIsMonthly(true);
      } else {
        setIsMonthly(false);
      }

      setIsPlan(true);
    }
  }, [plan]);

  const logoutAndRemoveUser = () => {
    dispatch(removeUser());
    dispatch(removePlan());
    setNotes([]);
    logout();
  };

  return (
    <>
      {!token && <HeaderHome />}
      <div className="flex flex-col">
        {token && (
          <div
            onClick={logoutAndRemoveUser}
            className="ml-auto pt-5 pr-7 text-gray-400 text-sm cursor-pointer"
          >
            Sign out
          </div>
        )}
        <div className="bg-white dark:bg-gray-950 dark:text-white py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div
              className={
                "mx-auto max-w-2xl sm:text-center " + (isPlan ? "my-10" : "")
              }
            >
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                Simple no-tricks pricing
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                {!plan?.extra?.subscribed_before &&
                  "All plans include a free 30 days trial."}{" "}
                Change plan or cancel at any time.
              </p>
            </div>

            {!plan.cycle_end_date && plan?.extra?.subscribed_before && (
              <div className="flex items-center bg-red-300 p-3 text-sm justify-center rounded-lg my-5">
                <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
                <span>
                  Your subscription of{" "}
                  <strong>{plan?.extra?.cancel_plan_name}</strong> got{" "}
                  {plan?.extra?.cancellation_details?.reason ===
                  "payment_failed"
                    ? "expired"
                    : "canceled"}
                  , to keeping using this product seamlessly choose a plan from
                  the following to get started again.
                </span>
              </div>
            )}

            {!!plans.length && user?.discount && (
              <div className="flex justify-center max-w-[14rem] mx-auto my-10">
                <div className="relative flex w-full p-1 bg-gray-100 rounded-full">
                  <span className="absolute inset-0 m-1 pointer-events-none">
                    <span
                      className={
                        "absolute inset-0 w-1/2 bg-slate-500 rounded-full shadow-sm shadow-slate-950/10 transform transition-transform duration-150 ease-in-out " +
                        (isMonthly ? "translate-x-0" : "translate-x-full")
                      }
                    ></span>
                  </span>
                  <button
                    onClick={() => setIsMonthly(true)}
                    className={
                      "relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-slate-300 transition-colors duration-150 ease-in-out " +
                      (isMonthly ? "text-white" : "text-slate-500 ")
                    }
                  >
                    Monthly
                  </button>
                  <button
                    onClick={() => setIsMonthly(false)}
                    className={
                      "relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-slate-300 transition-colors duration-150 ease-in-out " +
                      (isMonthly ? "text-slate-500" : "text-white")
                    }
                  >
                    Yearly
                  </button>
                  {user?.discount && (
                    <div className="dark:text-black bg-slate-100 px-2 absolute rounded-xl border min-w-[10px] -right-4 -top-4">
                      Save {Math.round(user?.discount)}%
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className={user?.discount ? "" : "mt-5"}>
              {!!plans.length &&
                plans.map((plan: Plan) => (
                  <PlanCard
                    key={plan?.id}
                    isMonthly={isMonthly}
                    plan={plan}
                    isPlan={isPlan}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      {!token && <FooterHome />}
    </>
  );
};

export default PaymentPlans;
