import ktx_logo_dark from "@/assets/ktx_logo_dark.svg";
import ktx_logo_white from "@/assets/ktx_logo_white.png";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { UserNav } from "./user-nav";
import { Sidebar } from "../sidebar/sidebar";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
  DrawerPortal,
  DrawerOverlay,
  DrawerTitle,
  DrawerDescription,
} from "@/components/ui/drawer";
import { ArchiveBoxIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { SUBSCRIPTION_STATUS } from "@/utils/constants";
import { useAppSelector } from "@/hooks";
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import Countdown, { CountdownRendererFn } from "react-countdown";
import { formatDateTime } from "@/utils/UtilFuncs";
import { ModeToggle } from "@/components/theme-toggle";
import useThemeStore from "@/utils/ThemeStore";

export default function Navbar() {
  const user = useAppSelector((state) => state.user.user);
  const plan = useAppSelector((state) => state.plan.plan);
  const { theme } = useThemeStore();

  const renderer: CountdownRendererFn = ({ days }) => {
    return <span> {days} days</span>;
  };

  return (
    <div className="border-b-[1px] border-gray-200 dark:border-gray-600">
      <div className="flex dark:bg-gray-800 sm:bg-gray-50 sm:dark:bg-[#37415159]">
        <div className="md:hidden flex shrink-0 items-center px-4">
          <Drawer direction="left" noBodyStyles>
            <DrawerTrigger asChild>
              <Bars3Icon className="h-6 w-6" />
            </DrawerTrigger>
            <DrawerPortal>
              <DrawerOverlay className="fixed inset-0 bg-black/40" />
              <DrawerContent
                className="bg-white dark:bg-gray-950 dark:text-white flex flex-col h-full w-[300px] mt-24 fixed bottom-0 right-0 sidebar-handle"
                style={{
                  borderRadius: 0,
                  overflow: "auto",
                  height: "100%",
                }}
              >
                <DrawerTitle></DrawerTitle>
                <DrawerDescription></DrawerDescription>
                <div className="flex shrink-0 items-center mt-2">
                  <img
                    className="h-8 w-auto"
                    src={theme === "dark" ? ktx_logo_white : ktx_logo_dark}
                    alt="Kortex"
                  />
                  Kortex.ai
                </div>
                <Sidebar
                  isCollapsed={false}
                  links={[
                    {
                      title: "Notes",
                      route: "/dashboard/notes",
                      icon: PencilSquareIcon,
                      variant: "default",
                    },
                    {
                      title: "Archive",
                      route: "/dashboard/archive",
                      icon: ArchiveBoxIcon,
                      variant: "ghost",
                    },
                    // {
                    //   title: "Templates",
                    //   route: "templates",
                    //   icon: PencilSquareIcon,
                    //   variant: "ghost",
                    // },
                    // {
                    //   title: "Reports",
                    //   route: "reports",
                    //   icon: ChartPieIcon,
                    //   variant: "ghost",
                    // },
                  ]}
                />
                {/* <Separator />
                <Sidebar
                  isCollapsed={false}
                  links={[
                    {
                      title: "GAEP",
                      route: "gaep",
                      icon: UserGroupIcon,
                      variant: "ghost",
                    },
                    {
                      title: "HSHS",
                      route: "hshs-sj",
                      icon: UserGroupIcon,
                      variant: "ghost",
                    },
                  ]}
                /> */}
                {[SUBSCRIPTION_STATUS.TRIAL].includes(
                  user?.subscription_status!
                ) && (
                  <div className="mx-2 rounded-xl border border-slate-500 p-3 text-xs text-center flex flex-col items-center">
                    <InformationCircleIcon className="my-1 h-5 w-5 text-slate-500" />
                    Your Trial Ends in
                    <div className="my-1">
                      <strong>
                        {plan?.trial_till && (
                          <Countdown
                            date={plan?.trial_till}
                            renderer={renderer}
                          />
                        )}
                      </strong>
                    </div>
                  </div>
                )}
              </DrawerContent>
            </DrawerPortal>
          </Drawer>
        </div>
        <div className="hidden md:flex shrink-0 items-center">
          <img
            className="ml-2 h-10 w-auto"
            src={theme === "dark" ? ktx_logo_white : ktx_logo_dark}
            alt="Kortex"
          />
          Kortex.ai
        </div>
        <div className="ml-auto">
          <div className="flex h-16 gap-2 items-center px-4">
            <ModeToggle />
            <UserNav />
          </div>
        </div>
      </div>
      {plan.unpaid_to_cancel && (
        <div className="flex items-center bg-red-400 p-3 text-xs justify-center">
          <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
          <span>
            <strong>Payment Due! </strong> Your payment is overdue. Please make
            your payment before{" "}
            <strong className="">
              {formatDateTime(plan.unpaid_to_cancel)}
            </strong>{" "}
            to continue using app.
          </span>
        </div>
      )}
    </div>
  );
}
