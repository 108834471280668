import HeaderHome from "../components/HeaderHome";
import FooterHome from "../components/FooterHome";

const TermsAndConditions: React.FC = () => {
  return (
    <>
      <HeaderHome />
      <div className="max-w-5xl mx-auto w-full">
        <h1 className="text-2xl">Terms and Conditions</h1>
        <h2 className="text-xl">Introduction</h2>
        <p>
          Welcome to Kortex.ai ("Platform", "we", "us", or "our"). These Terms
          and Conditions ("Terms") govern your access to and use of our medical
          documentation automation and scribe services ("Services"). By using
          our Services, you agree to be bound by these Terms. If you do not
          agree to these Terms, please do not use our Services.
        </p>

        <h2 className="text-xl">1. Acceptance of Terms</h2>
        <p>
          By accessing or using the Platform, you agree to comply with and be
          bound by these Terms. If you are using the Platform on behalf of an
          organization, you agree to these Terms on behalf of that organization
          and represent that you have the authority to do so.
        </p>

        <h2 className="text-xl">2. Modifications to Terms</h2>
        <p>
          We reserve the right to modify these Terms at any time. We will notify
          you of any changes by posting the new Terms on the Platform. Your
          continued use of the Platform after such modifications constitutes
          your acceptance of the new Terms.
        </p>

        <h2 className="text-xl">3. Eligibility</h2>
        <p>
          To use our Services, you must be at least 18 years old and have the
          legal capacity to enter into a binding agreement. By using the
          Platform, you represent and warrant that you meet these requirements.
        </p>

        <h2 className="text-xl">4. Account Registration</h2>
        <p>
          To access certain features of the Platform, you may be required to
          register for an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete. You are
          responsible for safeguarding your account credentials and for any
          activities or actions under your account.
        </p>

        <h2 className="text-xl">5. Use of Services</h2>
        <h3>5.1 Permitted Use</h3>
        <p>
          You agree to use the Services only for lawful purposes and in
          accordance with these Terms. You are solely responsible for your
          conduct while using the Platform and for any content you provide.
        </p>

        <h3>5.2 Prohibited Use</h3>
        <p>You agree not to:</p>
        <ul>
          <li>Use the Services for any illegal or unauthorized purpose.</li>
          <li>Modify, adapt, hack, or reverse engineer the Platform.</li>
          <li>
            Transmit any worms or viruses or any code of a destructive nature.
          </li>
          <li>Violate any applicable laws, regulations, or rules.</li>
          <li>
            Interfere with or disrupt the integrity or performance of the
            Platform.
          </li>
        </ul>

        <h2 className="text-xl">6. Privacy</h2>
        <p>
          Your use of the Platform is also governed by our Privacy Policy, which
          can be found at [Privacy Policy URL]. By using the Platform, you
          consent to the collection and use of your information as outlined in
          the Privacy Policy.
        </p>

        <h2 className="text-xl">7. Intellectual Property</h2>
        <p>
          All content and materials available on the Platform, including but not
          limited to text, graphics, website name, code, images, and logos, are
          the intellectual property of [Platform Name] and are protected by
          applicable copyright and trademark law. You agree not to reproduce,
          duplicate, copy, modify, sell, or exploit any portion of the Platform
          without our express written permission.
        </p>

        <h2 className="text-xl">8. Disclaimers</h2>
        <p>
          The Services are provided "as is" and "as available" without any
          warranties of any kind, either express or implied. We do not warrant
          that the Services will be uninterrupted or error-free, nor do we make
          any warranty as to the results that may be obtained from the use of
          the Services.
        </p>

        <h2 className="text-xl">9. Limitation of Liability</h2>
        <p>
          In no event shall [Platform Name], its affiliates, or their respective
          directors, officers, employees, or agents be liable for any indirect,
          incidental, special, consequential, or punitive damages, including but
          not limited to, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from:
        </p>
        <ul>
          <li>
            Your access to or use of or inability to access or use the Services.
          </li>
          <li>Any conduct or content of any third party on the Platform.</li>
          <li>Any content obtained from the Services.</li>
          <li>
            Unauthorized access, use, or alteration of your transmissions or
            content.
          </li>
        </ul>

        <h2 className="text-xl">10. Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold harmless [Platform Name] and
          its affiliates, and their respective directors, officers, employees,
          and agents, from and against any claims, liabilities, damages, losses,
          and expenses, including without limitation reasonable legal and
          accounting fees, arising out of or in any way connected with your
          access to or use of the Services or your violation of these Terms.
        </p>

        <h2 className="text-xl">11. Termination</h2>
        <p>
          We may terminate or suspend your account and access to the Services at
          our sole discretion, without prior notice or liability, for any
          reason, including if you breach these Terms. Upon termination, your
          right to use the Services will immediately cease.
        </p>

        <h2 className="text-xl">12. Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of [Your Jurisdiction], without regard to its conflict of law
          provisions.
        </p>

        <h2 className="text-xl">13. Dispute Resolution</h2>
        <p>
          Any disputes arising out of or relating to these Terms or the Services
          shall be resolved through binding arbitration in accordance with the
          rules of [Arbitration Organization] in [Location]. You agree to submit
          to the personal jurisdiction of the courts located within [Location]
          for the purpose of litigating any claims or disputes.
        </p>

        <h2 className="text-xl">14. Miscellaneous</h2>
        <p>
          If any provision of these Terms is held to be invalid or
          unenforceable, the remaining provisions shall continue in full force
          and effect. These Terms constitute the entire agreement between us
          regarding our Services and supersede any prior agreements we might
          have had.
        </p>

        <h2 className="text-xl">Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at
          [Contact Information].
        </p>
      </div>
      <FooterHome />
    </>
  );
};

export default TermsAndConditions;
