import React, { useRef } from "react";
import { useAppSelector } from "@/hooks";
import { formatDateTime } from "@/utils/UtilFuncs";
import { managePlan } from "@/utils/Services";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { SUBSCRIPTION_STATUS } from "@/utils/constants";
import Countdown, { CountdownRendererFn } from "react-countdown";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Button } from "@/components/ui/button";

const CurrentPlan: React.FC = () => {
  const plan = useAppSelector((state) => state.plan.plan);
  const pricing = plan?.plan_price;

  const isLoading = useRef(false);

  const renderer: CountdownRendererFn = ({ days }) => {
    return <span> {days} days</span>;
  };

  const onSubmit = async (e: any) => {
    e?.preventDefault();

    isLoading.current = true;
    try {
      const res = await managePlan();

      window.open(res.data.url);
    } catch (error: any) {
      toast.error("Some error has occured.");
    } finally {
      isLoading.current = false;
    }
  };

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <div className="flex">
          <h2 className="min-w-fit text-base font-semibold leading-7 text-gray-600 dark:text-white">
            Current Plan
          </h2>

          <div className="relative w-full">
            <InformationCircleIcon className="ml-1 my-1 h-5 w-5 text-slate-500" />
            <div
              style={{ top: "-35px", right: "-10px", left: "5px" }}
              className="rounded-lg border py-2 px-3 bg-white dark:bg-gray-950 dark:text-white text-[10px] top-0 opacity-0 hover:opacity-100 duration-300 absolute z-10"
            >
              <h3 className="font-semibold mb-1">*Upgrade/Downgrade Plans</h3>
              To change plan from annual to monthly you will need to cancel your
              ongoing yearly subscription and start a new one after it ends.
            </div>
          </div>
        </div>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Manage your current plan's invoices, payment method and subscription
          plans.
        </p>
      </div>

      <div className="md:col-span-2">
        {!plan.extra?.subscribed_before ||
        (plan.extra?.subscribed_before && isEmpty(plan.plan_price)) ? (
          <div className="flex items-center justify-center">
            You don't have any plan yet.
            <Link
              to="/pricing"
              className="ml-auto mt-5 flex items-center justify-center h-[40px] w-[150px] rounded-md bg-slate-900 dark:bg-white px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-500"
            >
              Choose New Plan
            </Link>
          </div>
        ) : (
          <div>
            <div className="flex">
              <h2 className="text-xl font-semibold mb-4">
                {pricing?.plan_title}
              </h2>
              {([SUBSCRIPTION_STATUS.TRIAL].includes(plan?.status!) ||
                plan?.cancel_at_period_end) && (
                <div className="ml-2 h-6 rounded-xl border px-2 py-1 text-xs text-center bg-slate-100 dark:bg-slate-500">
                  {plan?.cancel_at_period_end ? (
                    <span>
                      Subscription Ends in{" "}
                      <strong>
                        {plan?.cycle_end_date! && (
                          <Countdown
                            date={plan.cycle_end_date!}
                            renderer={renderer}
                          />
                        )}
                      </strong>
                    </span>
                  ) : (
                    <span>
                      Trial Ends in{" "}
                      <strong>
                        {plan?.trial_till && (
                          <Countdown
                            date={plan.trial_till}
                            renderer={renderer}
                          />
                        )}
                      </strong>
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="border-t border-b py-4">
              <div className="flex justify-between mb-2">
                <span>Pricing</span>
                <span>
                  $
                  {pricing?.tenure === "month"
                    ? pricing?.amount!.toFixed(2)
                    : (pricing?.amount! / 12).toFixed(2)}{" "}
                  / mo (Billed{" "}
                  {pricing?.tenure === "month" ? "Monthly" : "Yearly"})
                </span>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <span className="font-semibold">Last Paid invoice</span>
              <span>
                <strong>
                  $
                  {plan.extra?.amount_paid
                    ? plan.extra?.amount_paid!.toFixed(2)
                    : 0}
                </strong>{" "}
                paid on
                <strong> {formatDateTime(plan.extra?.invoice_paid_on!)}</strong>
              </span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="font-semibold">Renewal Date</span>
              <span>
                {plan.cancel_at_period_end
                  ? "N/A"
                  : formatDateTime(plan.cycle_end_date!)}
              </span>
            </div>
          </div>
        )}
        {plan.extra?.subscribed_before && (
          <>
            <Button
              onClick={onSubmit}
              disabled={isLoading.current}
              className="ml-auto mt-2 flex items-center justify-center h-[40px] min-w-[80px] rounded-md bg-sky-800 hover:bg-sky-600 text-white"
            >
              {isLoading.current ? (
                <span className="loader h-5 w-5"></span>
              ) : (
                <span>Manage Subscription</span>
              )}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CurrentPlan;
