import { create } from "zustand";
import { INote } from "@/utils/Types";

interface NoteState {
  theme: string;
  updateTheme: (theme: string) => void;
}

const useThemeStore = create<NoteState>((set) => ({
  theme: "dark",
  updateTheme: (theme) => set({ theme }),
}));

export default useThemeStore;
