import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { resendEmail, getUser } from "@/utils/Services";
import { saveUser, removeUser } from "@/slices/userSlice";
import { useAuthStore } from "@/utils/UseAuthStore";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import useNoteStore from "@/utils/NoteStore";
import { isEmpty } from "lodash";

interface ConfirmationEmailSentProps {}

const ConfirmationEmailSent: React.FC<ConfirmationEmailSentProps> = ({}) => {
  const [resending, setResending] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const logout = useAuthStore((state) => state.logoutWithRedirect);
  const { setNotes } = useNoteStore();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const verify = async () => {
      try {
        const res = await getUser();

        dispatch(saveUser({ ...res.data }));
      } catch (error) {}
    };

    if (isEmpty(user)) {
      verify();
    }

    if (user?.email_verified) {
      navigate("/dashboard/notes");
    }
  }, [user]);

  const logoutAndRemoveUser = () => {
    dispatch(removeUser());
    setNotes([]);
    logout();
  };

  const resend = async () => {
    setResending(true);
    try {
      await resendEmail();
      toast.success("Email sent successfully.");
    } catch (error) {
      toast.error("Resend email limit reached. Try again in 6 hours.");
    }
    setResending(false);
  };

  return (
    <div className="bg-gray-100 flex flex-col">
      <div
        onClick={logoutAndRemoveUser}
        className="ml-auto pt-5 pr-7 text-gray-400 text-sm cursor-pointer"
      >
        Sign out
      </div>
      <div className="min-h-screen flex flex-col items-center justify-center w-full">
        <div className="bg-white dark:bg-gray-950 dark:text-white p-6 mx-2 rounded-lg shadow-lg text-center lg:w-2/5 md:w-3/5 sm:w-full">
          <ExclamationCircleIcon className="w-10 h-10 mx-auto text-yellow-600" />
          <h1 className="my-3 text-2xl font-bold text-yellow-600">
            Verify Your Email Address
          </h1>
          <p className="mb-5 text-gray-600 dark:text-white">
            An Email has been sent successfully to
            <strong> "{user?.email}"</strong>. Please verify your email address.
          </p>
          <p className="mt-2 text-gray-600 dark:text-white">
            If you didn't receive the email, you can resend it.
          </p>
          <button
            onClick={resend}
            className="h-[40px] w-[130px] mt-6 px-4 py-2 rounded-lg text-white bg-green-600 hover:bg-green-700"
            disabled={resending}
          >
            {resending ? (
              <span className="loader h-5 w-5"></span>
            ) : (
              "Resend Email"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationEmailSent;
