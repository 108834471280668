import { useNavigate, useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "./UseAuthStore";
import { useEffect } from "react";
import { getUser, getCurrentPlan } from "../utils/Services";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { SUBSCRIPTION_STATUS } from "@/utils/constants";
import { saveUser } from "@/slices/userSlice";
import { savePlan } from "@/slices/planSlice";
import { isEmpty } from "lodash";

interface AuthRequiredProps {
  children?: React.ReactNode;
}

export const AuthRequired = ({ children }: AuthRequiredProps) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user);
  const plan = useAppSelector((state) => state.plan.plan);
  const token = useAuthStore((state) => state.accessToken);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getUser();

        dispatch(saveUser({ ...res.data }));
      } catch (error) {}
    };

    if (token) {
      fetchUser();
    }
  }, []);

  const planStatus = user?.subscription_status!;

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const res = await getCurrentPlan();

        dispatch(savePlan(res.data));
      } catch (error) {}
    };

    if (
      token &&
      !isEmpty(user) &&
      ![SUBSCRIPTION_STATUS.IN_ACTIVE].includes(planStatus)
    ) {
      fetchCurrentPlan();
    }
  }, [user]);

  const location = useLocation();

  if (!token) {
    return <Navigate to="/signin" replace state={{ from: location }} />;
  } else if (
    !isEmpty(user) &&
    !!token &&
    user?.is_staff &&
    window.location.pathname.startsWith("/dashboard") &&
    user.email_verified
  ) {
    return children ? <>{children}</> : <Outlet />;
  } else if (!isEmpty(user) && !!token && !user.email_verified) {
    return (
      <Navigate
        to="/registration/email-verification"
        replace
        state={{ from: location }}
      />
    );
  } else if (
    !isEmpty(user) &&
    token &&
    [SUBSCRIPTION_STATUS.IN_ACTIVE].includes(planStatus) &&
    !["/dashboard/settings/billing", "/payment"].includes(
      window.location.pathname
    )
  ) {
    if (isEmpty(plan.plan_price)) {
      return <Navigate to="/pricing" replace state={{ from: location }} />;
    } else if (!isEmpty(plan.plan_price)) {
      return <Navigate to="/payment" replace state={{ from: location }} />;
    }
  } else if (
    !isEmpty(user) &&
    token &&
    [SUBSCRIPTION_STATUS.TRIAL, SUBSCRIPTION_STATUS.ACTIVE].includes(
      planStatus
    ) &&
    ["/payment"].includes(window.location.pathname)
  ) {
    return (
      <Navigate to="/dashboard/notes" replace state={{ from: location }} />
    );
  }

  return children ? <>{children}</> : <Outlet />;
};
