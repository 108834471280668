import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { Plan } from "@/utils/Types";

interface PlanState {
  plan: Plan;
}

const initialState: PlanState = {
  plan: {},
};

export const planSlice = createSlice({
  name: "plan",

  initialState,
  reducers: {
    savePlan: (state, action: PayloadAction<object>) => {
      state.plan = { ...state.plan, ...action.payload };
    },
    removePlan: (state) => {
      state.plan = {};
    },
  },
});

export const { savePlan, removePlan } = planSlice.actions;
export const getPlan = (state: RootState) => state.plan;
export default planSlice.reducer;
