import clsx from "clsx";
import React from "react";
import { INote } from "../utils/Types";
import { NavLink, useParams } from "react-router-dom";
import { CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { keyBy, truncate } from "lodash";

interface ListItemProps {
  note: INote;
  onSmallScreenSelect?: () => void;
}

const ListItem: React.FC<ListItemProps> = ({ note, onSmallScreenSelect }) => {
  const { noteId = "", folder = "" } = useParams<{
    folder: string;
    noteId: string;
  }>();

  const itemClass = clsx(
    "rounded-lg border-2 border-gray-200 dark:border-gray-600 hover:bg-gray-100 hover:dark:bg-[#37415159] ",
    {
      "bg-gray-100 dark:bg-[#37415159]": note?.id === noteId,
    }
  );

  const content = (
    <div className={clsx("flex flex-col p-3 max-h-[112px]", {})}>
      <div className="flex items-center justify-between">
        <span className="flex items-center text-sm font-medium mr-2 truncate text-sky-800 dark:text-sky-300">
          {note?.chief_complaint
            ? truncate(note?.chief_complaint, { length: 30 })
            : "Untitled Note"}
          {note?.is_signed && (
            <CheckBadgeIcon className="text-green-400 dark:text-green-600 h-5 w-5 ml-1" />
          )}
        </span>

        {note?.room_num && (
          <div
            className={
              "flex items-center justify-center truncate h-[24px] w-[73px] border-[1px] border-gray-300 dark:border-gray-600 rounded-full bg-gray-50 dark:bg-[#37415173] text-gray-700 dark:text-gray-400 font-medium text-sm hover:bg-gray-50 hover:dark:bg-gray-700 hover:border-gray-300 hover:dark:border-gray-500 hover:text-gray-700 hover:dark:text-gray-200 " +
              (note?.id === noteId
                ? "bg-gray-50 dark:bg-gray-700 border-gray-300 dark:border-gray-500 text-gray-700 dark:text-gray-200"
                : "")
            }
          >
            {note?.room_num}
          </div>
        )}
      </div>
      <span className="text-sm font-medium mr-2 mt-1 truncate text-gray-500 dark:text-gray-300">
        {note?.first_name
          ? truncate(note?.first_name, { length: 100 })
          : note?.history_present_illness
          ? note?.history_present_illness
          : ""}
      </span>
      <div className="flex">
        <div className="flex items-center text-gray-400 mt-2 text-xs">
          <CalendarIcon className="h-4 w-4 mr-1 text-gray-400" />
          {new Date(note?.modified).toDateString()}
        </div>
        <div className="flex items-center text-gray-400 mt-2 ml-2 text-xs">
          <ClockIcon className="h-4 w-4 mr-1 text-gray-400" />
          {new Date(note?.modified).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </div>
    </div>
  );

  return (
    <li className={itemClass}>
      {
        <NavLink
          to={`${note.id}`}
          className={({ isActive }) =>
            clsx("relative focus:outline-slate-500", {
              "text-slate-400": isActive,
              "block text-slate-800": !isActive,
            })
          }
          onClick={() => onSmallScreenSelect?.()}
        >
          <>
            {note.status && (
              <div
                className={
                  "absolute h-[10px] w-[10px] top-[-5px] right-[-5px] rounded-full " +
                  (note?.status === "UPDATED" ? "bg-blue-500" : "bg-red-500")
                }
              ></div>
            )}
            {content}
          </>
        </NavLink>
      }
    </li>
  );
};

export default ListItem;
