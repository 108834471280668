import HeaderHome from "../components/HeaderHome";
import FooterHome from "../components/FooterHome";

const Privacy: React.FC = () => {
  return (
    <>
      <HeaderHome />
      <div className="max-w-5xl mx-auto w-full">
        <h1 className="text-2xl">Privacy Policy</h1>
        <p>Effective Date: [Insert Date]</p>

        <h2 className="text-xl">1. Introduction</h2>
        <p>
          Welcome to Kortex.ai ("Company", "we", "our", "us"). This Privacy
          Policy explains how we collect, use, disclose, and safeguard your
          information when you use our medical documentation automation and
          scribe SaaS app (the "App"). Please read this privacy policy
          carefully. If you do not agree with the terms of this privacy policy,
          please do not access the App.
        </p>

        <h2 className="text-xl">2. Information We Collect</h2>
        <h3>2.1 Personal Information</h3>
        <p>
          We may collect personal information that you provide to us when you
          register for an account, use the App, or communicate with us. This
          information may include:
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Professional credentials</li>
        </ul>

        <h3>2.2 Medical Information</h3>
        <p>
          In the course of using the App, you may input patient information,
          including but not limited to:
        </p>
        <ul>
          <li>Patient names</li>
          <li>Medical histories</li>
          <li>Diagnosis and treatment information</li>
          <li>Clinical notes</li>
        </ul>
        <p>
          We understand the sensitivity of this information and take privacy and
          security seriously.
        </p>

        <h3>2.3 Usage Data</h3>
        <p>
          We may collect information about your interactions with the App,
          including:
        </p>
        <ul>
          <li>Device information (e.g., IP address, browser type)</li>
          <li>Log data (e.g., access times and dates)</li>
          <li>Usage patterns and preferences</li>
        </ul>

        <h2 className="text-xl">3. How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide and maintain the App</li>
          <li>Improve, personalize, and expand our services</li>
          <li>Communicate with you, including for customer support</li>
          <li>Ensure compliance with legal and regulatory requirements</li>
          <li>Analyze usage trends and gather statistical information</li>
        </ul>

        <h2 className="text-xl">4. How We Share Your Information</h2>
        <p>We may share your information with:</p>
        <ul>
          <li>Service providers who perform services on our behalf</li>
          <li>
            Regulatory bodies and law enforcement officials as required by law
          </li>
          <li>Other third parties with your consent or at your direction</li>
        </ul>
        <p>
          We do not sell, trade, or rent your personal or medical information to
          others.
        </p>

        <h2 className="text-xl">5. Data Security</h2>
        <p>
          We use administrative, technical, and physical security measures to
          protect your personal and medical information. However, no method of
          transmission over the Internet, or method of electronic storage, is
          100% secure, and we cannot guarantee its absolute security.
        </p>

        <h2 className="text-xl">6. Your Privacy Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal
          information. You may also object to or restrict certain processing of
          your data. To exercise these rights, please contact us at [contact
          email].
        </p>

        <h2 className="text-xl">7. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page. You
          are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <h2 className="text-xl">8. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <p>Email: [contact email]</p>
        <p>Address: [Company Address]</p>
      </div>
      <FooterHome />
    </>
  );
};

export default Privacy;
