import React, { useRef, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AccountFormInput } from "@/utils/Types";
import { updateUserPassword } from "@/utils/Services";
import { toast } from "react-toastify";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

const ChangePassword: React.FC = () => {
  const isLoading = useRef(false);

  const errorStyle = {
    color: "red",
    fontSize: "12px",
    marginTop: "2px",
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<AccountFormInput>();

  const password = watch("password", "");
  const passwordError = useRef("");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "password") {
        passwordError.current = "";
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit: SubmitHandler<AccountFormInput> = async (formData, e) => {
    e?.preventDefault();

    const data = {
      old_password: formData.oldPassword,
      new_password1: formData.password,
      new_password2: formData.confirmPassword,
    };

    isLoading.current = true;

    try {
      await updateUserPassword(data);

      reset();

      toast.success("Password Updated Successfully!");
    } catch (error: any) {
      if (
        error.response.data.old_password &&
        error.response.data.old_password[0] ===
          "Your old password was entered incorrectly. Please enter it again."
      ) {
        toast.error(
          "Your old password was entered incorrectly. Please enter it again."
        );
      } else if (error?.response?.data?.non_field_errors) {
        passwordError.current = error?.response?.data?.non_field_errors[0];
        toast.error(passwordError.current);
      } else {
        toast.error("Some error has occured.");
      }
    } finally {
      isLoading.current = false;
    }
  };

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-600 dark:text-white">
          Change password
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Update your password associated with your account.
        </p>
      </div>

      <form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full">
            <Label htmlFor="current-password">Current password</Label>
            <div className="mt-2">
              <Input
                id="current-password"
                {...register("oldPassword", {
                  required: "Current Password is required",
                })}
                type="password"
                autoComplete="current-password"
              />
              <div style={errorStyle}>
                {errors.oldPassword && <p>{errors.oldPassword.message}</p>}
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <Label htmlFor="new-password">New password</Label>
            <div className="mt-2">
              <Input
                id="new-password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })}
                type="password"
                autoComplete="new-password"
              />
              <div style={errorStyle}>
                {passwordError.current ||
                  (errors.password && <p>{errors.password.message}</p>)}
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <Label htmlFor="confirm-password">Confirm password</Label>
            <div className="mt-2">
              <Input
                id="confirm-password"
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
                type="password"
                autoComplete="new-password"
              />
              <div style={errorStyle}>
                {errors.confirmPassword && (
                  <p>{errors.confirmPassword.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <Button
            type="submit"
            disabled={isLoading.current}
            className="flex items-center justify-center h-[40px] w-[80px] rounded-md bg-sky-800 hover:bg-sky-600 text-white"
          >
            {isLoading.current ? (
              <span className="loader h-5 w-5"></span>
            ) : (
              <span>Save</span>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
