import React, {
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  useCallback,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { TooltipProvider } from "@/components/ui/tooltip";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { cn } from "@/lib/utils";
import { client } from "../utils/AxiosClient";
import toast from "react-hot-toast";
import { getNoteById, getNotes, createNewNote } from "../utils/Services";
import useNoteStore from "../utils/NoteStore";
import ListItem from "../components/ListItem";
import EmptyNotes from "./EmptyNotes";
import { isEmpty } from "lodash";
import { SUBSCRIPTION_STATUS } from "@/utils/constants";
import { useAppSelector } from "../hooks";
import { INote } from "../utils/Types";
const SOCKET_AUTH_URL = import.meta.env.VITE_SOCKET_AUTH_URL as string;
const SOCKET_URL = import.meta.env.VITE_SOCKET_URL as string;

// Define constants for status values
const SOCKET_STATUS_CONNECTED = "Connected";
const SOCKET_STATUS_CREATED = "created";
const SOCKET_STATUS_UPDATED = "updated";
const SOCKET_STATUS_MESSAGE = "message_processed";
const SOCKET_STATUS_USER_UPDATED = "user_updated";

const Notes: React.FC = () => {
  const isLoading = useRef(false);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const [inputText, setInputText] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [notesCount, setNotesCount] = useState(0);
  const user = useAppSelector((state) => state.user.user);

  const [textkey, setTextkey] = useState("");
  const { height, width } = useWindowDimensions();

  const { noteId = "", folder = "" } = useParams<{
    folder: string;
    noteId: string;
  }>();

  const navigate = useNavigate();
  const {
    notes,
    setNotes,
    updateNote,
    setActiveNoteId,
    toggleArchiveForNote,
    deleteNote,
  } = useNoteStore();

  const fetchNotes = async (search = "", replace: boolean = false) => {
    try {
      const response = await getNotes("notes", {
        ...(search && { search: search }),
        limit: limit,
        offset: offset,
        ...(folder !== "archive" && { default: 1 }),
        ...(folder === "archive" && { archived: 1 }),
      });

      if (replace) {
        setNotes(response?.results);
      } else {
        setNotes([...notes, ...response?.results]);
      }
      setNotesCount(response?.count);
    } catch (error) {}
  };

  const fetchMoreNotes = () => {
    try {
      setOffset(offset + limit);
    } catch (error) {}
  };

  useEffect(() => {
    const note = notes.find((note: INote) => note.id === noteId);
    // @ts-ignore
    updateNote({ ...note, status: "" });
  }, [noteId]);

  useEffect(() => {
    if (offset >= limit && notes.length) {
      fetchNotes();
    } else {
      setOffset(0);
    }
  }, [offset]);

  useEffect(() => {
    if (
      !notes.length &&
      !offset &&
      !isEmpty(user) &&
      (user?.is_staff
        ? true
        : ![SUBSCRIPTION_STATUS.IN_ACTIVE].includes(user?.subscription_status!))
    ) {
      fetchNotes(inputText);
    }
  }, [user, notes.length, offset, folder]);

  useEffect(() => {
    setNotes([]);
    setNotesCount(0);
    setOffset(0);
  }, [folder]);

  const debounceFn = useCallback(debounce(fetchNotes, 500), []);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
    debounceFn(e.target.value);
  };

  // Establish Socket connection
  useEffect(() => {
    const openSocket = async () => {
      try {
        const {
          data: { uuid: uuidToken },
        } = await client.get(SOCKET_AUTH_URL);

        const newSocket = new WebSocket(`${SOCKET_URL}?uuid=${uuidToken}`);

        newSocket.onerror = function (event) {
          console.error("WebSocket error observed:", event);
        };

        newSocket.onclose = function (event) {
          if (event.wasClean) {
          } else {
            // e.g. server process killed or network down
          }
        };

        newSocket.onopen = () => {
          setSocket(newSocket);
          setSocketConnected(true);
        };
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened in setting up the request that triggered an Error
        }
        console.error("Error opening WebSocket:", error);
      }
    };

    if (!socket) {
      openSocket();
    }

    // Clean up the socket connection when the component unmounts
    return () => {
      if (socket) {
        socket.close();
      }
      setSocketConnected(false);
    };
  }, [socket]);

  // Listen for messages on socket connection
  useEffect(() => {
    if (socket) {
      // Listen for messages from the server
      socket.onmessage = async (event) => {
        try {
          const message = JSON.parse(event.data);
          const { note_id, status } = message.message;

          if (status === SOCKET_STATUS_CONNECTED) {
            return;
          }

          if (status === SOCKET_STATUS_CREATED) {
            fetchNotes("", true);

            if (status === SOCKET_STATUS_USER_UPDATED) {
              return;
            }
          } else if (
            status === SOCKET_STATUS_UPDATED ||
            status === SOCKET_STATUS_MESSAGE
          ) {
            const updatedNote = await getNoteById("notes", note_id);
            updateNote({ ...updatedNote, status: "UPDATED" });
          } else if (status === "error") {
            const updatedNote = await getNoteById("notes", note_id);
            updateNote({ ...updatedNote, status: "ERROR" });
          } else if (status === "archived") {
            setNotes([]);
          }
        } catch (error) {
          console.error("Error while parsing socket message:", error);
        }
      };
    }
  }, [socket]);

  const addNewNote = async () => {
    try {
      const res = await createNewNote();
      navigate(`/dashboard/notes/${res?.data.id}`);
    } catch (error) {}
  };

  const filterAndSortNotes = (notes: INote[]) => {
    return notes
      .filter((note) => (folder === "archive" ? note.archived : !note.archived))
      .sort(
        // @ts-ignore
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
      );
  };

  const navigateNextNote = (filteredNotes: INote[], noteIndex: number) => {
    const nextNoteIndex = noteIndex + 1;
    const previousNoteIndex = noteIndex - 1;

    if (nextNoteIndex < filteredNotes.length) {
      const nextId = filteredNotes[nextNoteIndex]?.id?.toString();
      navigate(`/dashboard/${folder}/${nextId}`);
    } else if (previousNoteIndex >= 0) {
      const previousId = filteredNotes[previousNoteIndex]?.id?.toString();
      navigate(`/dashboard/${folder}/${previousId}`);
    } else {
      navigate(`/dashboard/${folder}/empty-notes`);
      setActiveNoteId("");
    }
  };

  const handleArchiveNote = async (noteId: string) => {
    const filteredNotes = filterAndSortNotes(notes);
    const noteIndex = filteredNotes.findIndex((note) => note.id === noteId);
    const note = filteredNotes[noteIndex];
    await toggleArchiveForNote(noteId);
    if (filteredNotes.length > 0 && noteIndex !== -1) {
      navigateNextNote(filteredNotes, noteIndex);
    }
    const msg = note?.archived ? "Moved to Notes" : "Note Archived";

    toast.success(msg, {
      duration: 1000,
      position: "top-center",
    });
  };

  const handleDeleteNote = async (noteId: string) => {
    const filteredNotes = filterAndSortNotes(notes);
    const noteIndex = filteredNotes.findIndex((note) => note.id === noteId);

    if (filteredNotes.length > 0 && noteIndex !== -1) {
      navigateNextNote(filteredNotes, noteIndex);
    }
    await deleteNote(noteId);
    toast.success("Note Deleted!", {
      duration: 1000,
      position: "top-center",
    });
  };

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          defaultSize={20}
          collapsedSize={18}
          collapsible={true}
          minSize={18}
          maxSize={50}
          onResize={(collapsed) => {
            setTextkey(collapsed.toString());
          }}
          className={cn(
            noteId ? "" : "flex flex-col items-center",
            width <= 768 && noteId ? "hidden" : ""
          )}
        >
          <div
            className={
              "py-3 " +
              (!noteId && width <= 768
                ? "px-2 w-full"
                : noteId && width >= 768
                ? "px-4"
                : "flex items-center w-full md:w-[807px] px-2")
            }
          >
            {(noteId || width <= 768) && (
              <div className="flex items-center">
                <span className="font-semibold">Notes</span>
                <Button
                  type="submit"
                  onClick={addNewNote}
                  disabled={isLoading.current}
                  className="ml-auto flex items-center justify-center h-[40px] w-[80px] rounded-md bg-sky-800 hover:bg-sky-600 text-white"
                >
                  {isLoading.current ? (
                    <span className="loader h-5 w-5"></span>
                  ) : (
                    <>
                      <PlusCircleIcon className="h-5 w-5 mr-1" />
                      <span>Add</span>
                    </>
                  )}
                </Button>
              </div>
            )}

            <Input
              type="search"
              placeholder="Search..."
              onChange={handleInputChange}
              className={noteId || width <= 768 ? "mt-5" : "w-[300px]"}
            />

            {!noteId && width >= 768 && (
              <Button
                type="submit"
                disabled={isLoading.current}
                onClick={addNewNote}
                className="ml-auto flex items-center justify-center h-[40px] w-[80px] rounded-md bg-sky-800 hover:bg-sky-600 text-white"
              >
                {isLoading.current ? (
                  <span className="loader h-5 w-5"></span>
                ) : (
                  <>
                    <PlusCircleIcon className="h-5 w-5 mr-1" />
                    <span>Add</span>
                  </>
                )}
              </Button>
            )}
          </div>
          <div
            id="scrollableDiv"
            className={
              "bg-white dark:bg-gray-950 dark:text-white " +
              (noteId ? "px-2 " : "w-full md:w-[807px] ")
            }
            style={
              noteId
                ? { overflow: "auto", height: "calc(100dvh - 210px)" }
                : { overflow: "auto", height: "calc(100dvh - 130px)" }
            }
          >
            {notes.length ? (
              <InfiniteScroll
                dataLength={notes.length}
                next={fetchMoreNotes}
                hasMore={notesCount > notes.length}
                loader={
                  <div className="w-full text-center p-2">
                    <span className="loader w-5 h-5"></span>
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <ul role="list" className="space-y-3 mx-2 mt-2">
                  {notes.map((note) => (
                    <ListItem key={note.id} note={note} />
                  ))}
                </ul>
              </InfiniteScroll>
            ) : (
              <div className="h-full flex items-center">
                <EmptyNotes />
              </div>
            )}
          </div>
        </ResizablePanel>
        {noteId && (
          <>
            {width >= 768 && <ResizableHandle withHandle />}
            <ResizablePanel
              defaultSize={60}
              style={{
                overflow: "auto",
                height: "100%",
              }}
            >
              <Outlet
                context={{
                  handleDeleteNote,
                  handleArchiveNote,
                }}
              />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </TooltipProvider>
  );
};

export default Notes;
