import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { verifyEmail } from "../utils/Services";

const EmailConfirmation: React.FC = () => {
  const [confirmed, setConfirmed] = useState(false);

  const { key = "" } = useParams<{
    key: string;
  }>();

  useEffect(() => {
    const verify = async () => {
      if (key) {
        try {
          await verifyEmail(key);
          setConfirmed(true);
        } catch (error) {
          setConfirmed(false);
        }
      }
    };

    verify();
  }, [key]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center w-full">
      <div className="bg-white dark:bg-gray-950 dark:text-white p-6 rounded-lg shadow-lg text-center lg:w-2/5 md:w-3/5 sm:w-full">
        {confirmed ? (
          <CheckCircleIcon className="w-10 h-10 mx-auto text-green-600" />
        ) : (
          <XCircleIcon className="w-10 h-10 mx-auto text-red-600" />
        )}
        <h1
          className={
            "my-3 text-2xl font-bold " +
            (confirmed ? "text-green-600" : "text-red-600")
          }
        >
          {confirmed ? "Email Confirmed!" : "Email Confirmation Failed"}
        </h1>
        <p className="my-4 text-gray-600 dark:text-white">
          {confirmed
            ? "Thank you for confirming your email address."
            : "The email confirmation key is invalid."}
        </p>
        <Link
          to={confirmed ? "/dashboard/notes" : "/"}
          className="mt-6 px-4 py-2 bg-slate-500 text-white rounded-lg hover:bg-slate-800"
        >
          {confirmed ? "Go To Dashboard" : "Go Back"}
        </Link>
      </div>
    </div>
  );
};

export default EmailConfirmation;
