import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ArrowCounterClockwise, Info } from "@phosphor-icons/react";
import { retryMessage } from "../utils/Services";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";

interface RetryModalProps {
  message: {
    id: string;
    extra?: {
      err_msg: string;
    };
  };
}

const RetryModal: React.FC<RetryModalProps> = ({ message }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputText, setInputText] = useState("");

  const handleInputChange = (e: any) => {
    setInputText(e.target.value);
  };

  const retry = async (id: string) => {
    try {
      await retryMessage(id, {
        additional_info: inputText,
      });
    } catch (error) {}
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className="ml-auto flex items-center justify-center rounded-full bg-gray-500 h-[24px] text-xs">
          <ArrowCounterClockwise className="text-gray-50 mr-1" size={15} />
          <span className="text-gray-50">Retry</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full sm:max-w-[600px] dark:bg-gray-900">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center">
              <Info size={25} />
              <span className="ml-2">Errors</span>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col p-4 max-h-[70vh] overflow-auto">
          <div className="flex items-center">
            <div className="h-2 w-2 bg-red-600 dark:bg-red-400 rounded-full"></div>
            <span className="ml-2 text-red-600 dark:text-red-400">
              {message?.extra!.err_msg}
            </span>
          </div>

          <div className="my-2">
            <Label className="">Add missing Information</Label>
            <Textarea
              onChange={handleInputChange}
              placeholder="Add text here"
              className="mt-4 mb-5"
            />
          </div>
          <Button
            onClick={() => {
              retry(message.id);
              setIsOpen(false);
            }}
            variant="default"
            className="ml-auto h-10 w-[100px] rounded-lg"
          >
            Update
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RetryModal;
