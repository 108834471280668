import { create } from "zustand";
import { INote } from "@/utils/Types";
import { deleteNoteByID, archiveNote } from "./Services";

// TODO: Clean up note store, using react or zustand for notes?
interface NoteState {
  notes: INote[];
  setNotes: (notes: INote[]) => void;
  toggleArchiveForNote: (noteId: string) => Promise<void>;
  deleteNote: (noteId: string) => Promise<void>;
  activeNoteId: string | null;
  setActiveNoteId: (id: string | null) => void;
  addNote: (note: INote) => void;
  updateNote: (updatedNote: INote) => void;
}

const useNoteStore = create<NoteState>((set) => ({
  notes: [],
  setNotes: (notes) => set({ notes }),
  activeNoteId: null,
  setActiveNoteId: (id) => set({ activeNoteId: id }),
  addNote: (note) => set((state) => ({ notes: [...state.notes, note] })),

  // Action to update a note from autosave
  updateNote: (updatedNote) =>
    set((state) => {
      const updatedNotes = state.notes.map((note) =>
        note.id === updatedNote.id ? updatedNote : note
      );
      return { notes: updatedNotes };
    }),

  // Action to toggle the archive status of a note
  toggleArchiveForNote: async (noteId) => {
    try {
      await archiveNote("notes", noteId);
    } catch (error) {
      console.error("Failed to toggle note status:", error);
      // Handle error appropriately
    }
  },

  // Action to delete a note
  deleteNote: async (noteId) => {
    try {
      // Delete the note by ID (async operation)
      await deleteNoteByID("notes", noteId);

      // Then update the state to remove the note
      set((state) => ({
        notes: state.notes.filter((note) => note.id !== noteId),
      }));
    } catch (error) {
      console.error("Failed to delete note:", error);
      // Handle error appropriately
    }
  },
}));

export default useNoteStore;
