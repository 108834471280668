import React, { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AccountFormInput } from "@/utils/Types";
import ConfirmationModal from "@/components/ConfirmationModal";
import { deleteAccount } from "@/utils/Services";
import { toast } from "react-toastify";
import { useAuthStore } from "@/utils/UseAuthStore";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

const DeleteAccount: React.FC = () => {
  const isLoading = useRef(false);
  const password = useRef("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const logout = useAuthStore((state) => state.logoutWithRedirect);

  const errorStyle = {
    color: "red",
    fontSize: "12px",
    marginTop: "2px",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AccountFormInput>();

  const onSubmit: SubmitHandler<AccountFormInput> = async (formData, e) => {
    e?.preventDefault();

    password.current = formData.oldPassword || "";
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmAction = async () => {
    const data = {
      password: password.current,
    };

    isLoading.current = true;

    try {
      await deleteAccount(data);
      reset();
      logout();

      toast.success("Account Deleted Successfully!");
    } catch (error: any) {
      toast.error(
        "Your password was entered incorrectly. Please enter it again."
      );
    } finally {
      isLoading.current = false;
    }

    setIsModalOpen(false);
  };

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-600 dark:text-white">
          Delete account
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          No longer want to use our service? You can delete your account here.
          This action is not reversible. All information related to this account
          will be deleted permanently.
        </p>
      </div>

      <form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full">
            <Label htmlFor="delete-password">Your password</Label>
            <div className="mt-2">
              <Input
                id="delete-password"
                {...register("oldPassword", {
                  required: "Your password is required",
                })}
                type="password"
                autoComplete="current-password"
              />
              <div style={errorStyle}>
                {errors.oldPassword && <p>{errors.oldPassword.message}</p>}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <Button
            type="submit"
            disabled={isLoading.current}
            className="w-[160px] h-[40px] rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
          >
            {isLoading.current ? (
              <div className="loader h-5 w-5"></div>
            ) : (
              <span>Yes, delete my account</span>
            )}
          </Button>
        </div>
      </form>

      <ConfirmationModal
        isOpen={isModalOpen}
        heading="Delete account"
        message="Are you sure you want to delete your account? All of your data will be permanently removed. This action cannot be undone."
        onClose={handleCloseModal}
        onConfirm={handleConfirmAction}
      />
    </div>
  );
};

export default DeleteAccount;
