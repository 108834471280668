import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { getClientSecret } from "@/utils/Services";
import { useEffect, useState } from "react";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE);

export default function PaymentMethod() {
  const [client, setClient] = useState("");

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const res = await getClientSecret();

        setClient(res.data.client_secret);
      } catch (error) {}
    };

    fetchClient();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      document.querySelector("html")?.classList.add("dark");
    } else {
      document.querySelector("html")?.classList.remove("dark");
    }
  }, []);

  const options = {
    clientSecret: client,
    appearance: {
      theme: localStorage.getItem("theme") === "dark" ? "night" : "default",
    },
  };

  return (
    <>
      {client && (
        // @ts-ignore
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}
