import HeaderHome from "../components/HeaderHome";
import FooterHome from "../components/FooterHome";

const Hipaa: React.FC = () => {
  return (
    <>
      <HeaderHome />
      <div className="max-w-5xl mx-auto w-full">
        <h1 className="text-2xl">HIPAA Compliance Policy</h1>
        <h2 className="text-xl">Introduction</h2>
        <p>
          Welcome to Kortex.ai ("Platform", "we", "us", or "our"). We are
          committed to maintaining the confidentiality, integrity, and security
          of protected health information (PHI) in accordance with the Health
          Insurance Portability and Accountability Act of 1996 (HIPAA). This
          HIPAA Compliance Policy outlines how we protect and handle PHI when
          providing our medical documentation automation and scribe services
          ("Services").
        </p>

        <h2 className="text-xl">1. Definitions</h2>
        <ul>
          <li>
            <strong>Protected Health Information (PHI):</strong> Any
            information, including demographic data, that relates to an
            individual's health status, provision of healthcare, or payment for
            healthcare that can be linked to a specific individual.
          </li>
          <li>
            <strong>Covered Entity:</strong> A healthcare provider, health plan,
            or healthcare clearinghouse that transmits any health information in
            electronic form.
          </li>
          <li>
            <strong>Business Associate:</strong> A person or entity that
            performs certain functions or activities on behalf of, or provides
            certain services to, a covered entity that involve the use or
            disclosure of PHI.
          </li>
        </ul>

        <h2 className="text-xl">2. Use and Disclosure of PHI</h2>
        <p>
          We will use and disclose PHI only as permitted or required by HIPAA
          regulations, including:
        </p>
        <ul>
          <li>
            For the provision of Services to covered entities and business
            associates.
          </li>
          <li>
            For payment purposes, including billing and collection activities.
          </li>
          <li>
            For healthcare operations, including quality assessment and
            improvement activities, and business management and general
            administrative activities.
          </li>
          <li>
            As required by law or to avert a serious threat to health or safety.
          </li>
          <li>
            With the individual's written authorization for any other use or
            disclosure not permitted or required by law.
          </li>
        </ul>

        <h2 className="text-xl">3. Safeguards</h2>
        <p>
          We have implemented appropriate administrative, physical, and
          technical safeguards to protect the privacy and security of PHI,
          including:
        </p>
        <ul>
          <li>
            Access controls to ensure that only authorized personnel can access
            PHI.
          </li>
          <li>Encryption of PHI during transmission and storage.</li>
          <li>
            Regular training for employees on HIPAA requirements and the
            importance of protecting PHI.
          </li>
          <li>
            Policies and procedures to address potential security incidents and
            breaches.
          </li>
        </ul>

        <h2 className="text-xl">4. Individual Rights</h2>
        <p>Individuals have certain rights regarding their PHI, including:</p>
        <ul>
          <li>The right to access and obtain a copy of their PHI.</li>
          <li>
            The right to request an amendment to their PHI if they believe it is
            incorrect or incomplete.
          </li>
          <li>
            The right to request restrictions on certain uses and disclosures of
            their PHI.
          </li>
          <li>
            The right to receive an accounting of disclosures of their PHI.
          </li>
          <li>
            The right to request confidential communications about their PHI.
          </li>
        </ul>

        <h2 className="text-xl">5. Breach Notification</h2>
        <p>
          In the event of a breach of unsecured PHI, we will notify the affected
          individuals, the Department of Health and Human Services (HHS), and,
          in certain circumstances, the media, in accordance with HIPAA breach
          notification requirements.
        </p>

        <h2 className="text-xl">6. Complaints</h2>
        <p>
          If you believe your privacy rights have been violated, you may file a
          complaint with us or with the Secretary of HHS. We will not retaliate
          against you for filing a complaint.
        </p>

        <h2 className="text-xl">7. Contact Information</h2>
        <p>
          If you have any questions about this HIPAA Compliance Policy or our
          privacy practices, please contact us at:
        </p>
        <p>[Contact Information]</p>

        <h2 className="text-xl">8. Changes to this Policy</h2>
        <p>
          We may update this HIPAA Compliance Policy from time to time. We will
          notify you of any changes by posting the new policy on our Platform.
          Your continued use of the Services after any modifications to the
          policy constitutes your acceptance of the updated policy.
        </p>
      </div>
      <FooterHome />
    </>
  );
};

export default Hipaa;
