import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../utils/UseAuthStore";
// import HeaderHome from "../components/HeaderHome";
// import BodyHome from "../components/BodyHome";
// import FooterHome from "../components/FooterHome";

const HomeLanding: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn());

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard/notes");
    } else {
      navigate("/signin");
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      {/* <HeaderHome />
			<BodyHome />
			<FooterHome /> */}
    </>
  );
};

export default HomeLanding;
