import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useEffect, useRef, useState, KeyboardEvent } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { postMessage, getMessages, clearMessages } from "../utils/Services";
import { client } from "../utils/AxiosClient";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useAppSelector } from "@/hooks";
import NoteDropdown from "../components/NoteDropdown";
import {
  StarFour,
  PaperPlaneRight,
  Microphone,
  X,
  ClockCounterClockwise,
  Check,
} from "@phosphor-icons/react";
import RetryModal from "@/components/RetryModal";

interface Message {
  id: string;
  type: string;
  text?: string;
  created?: string;
  from_audio: boolean;
  processing_status?: string;
  transcribed?: boolean;
  extra?: {
    err_msg: string;
  };
}

interface ConfirmationModalProps {
  onClose: () => void;
  isLoad: boolean;
}

const Copilot: React.FC<ConfirmationModalProps> = ({ onClose, isLoad }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const socket = useRef<WebSocket | null>(null);
  const [socketResponse, setSocketResponse] = useState(
    {} as {
      status: string;
      object: Message;
    }
  );
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [messagesCount, setMessagesCount] = useState(0);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const isCancel = useRef<boolean>(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const recordingIntervalRef = useRef<number | null>(null);
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const { height, width } = useWindowDimensions();
  const user = useAppSelector((state) => state.user.user);

  const navigate = useNavigate();

  const { noteId = "", folder = "" } = useParams<{
    folder: string;
    noteId: string;
  }>();

  const fetchMessages = async (
    noteId: string,
    replace: boolean = false,
    off = 0
  ) => {
    setIsLoading(true);
    try {
      const response = await getMessages({
        note_id: noteId,
        offset: off,
        limit: limit,
      });
      setMessagesCount(response.count);

      if (replace) {
        setMessages([
          ...response.results,
          ...(!response.count
            ? [
                {
                  id: Date.now().toString(),
                  text: "Create a new note by choosing either the text or audio option",
                  type: "bot",
                  from_audio: false,
                },
              ]
            : []),
        ]);
      } else {
        setMessages([
          ...messages,
          ...response.results,
          ...(!response.count
            ? [
                {
                  id: Date.now().toString(),
                  text: "Create a new note by choosing either the text or audio option",
                  type: "bot",
                  from_audio: false,
                },
              ]
            : []),
        ]);
      }
    } catch (error) {
      console.error("Failed to load profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (messagesCount && messagesCount === messages.length) {
      setMessages([
        ...messages,
        {
          id: Date.now().toString(),
          text: "Create a new note by choosing either the text or audio option",
          type: "bot",
          from_audio: false,
        },
      ]);
    }
  }, [messages]);

  const loadMoreMessages = () => {
    if (messagesCount > messages.length) {
      setOffset(offset + limit + newMessagesCount);
      fetchMessages(noteId, false, offset + limit + newMessagesCount);
      setNewMessagesCount(0);
    }
  };

  useEffect(() => {
    return () => {
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, [noteId]);

  useEffect(() => {
    if (noteId) {
      setNewMessagesCount(0);
      setMessagesCount(0);
      setInputText("");
      setOffset(0);
      setMessages([]);
      const res = setTimeout(() => {
        fetchMessages(noteId, true);
      }, 500);

      return () => {
        clearTimeout(res);
        if (socket.current) {
          socket.current.close();
          socket.current = null;
        }
      };
    }
  }, [noteId]);

  useEffect(() => {
    const openSocket = async () => {
      try {
        const {
          data: { uuid: uuidToken },
        } = await client.get(
          `${import.meta.env.VITE_SOCKET_AUTH_URL}?note_id=${noteId}`
        );

        socket.current = new WebSocket(
          `${import.meta.env.VITE_SOCKET_URL_MESSAGE}?uuid=${uuidToken}`
        );

        socket.current.onerror = function (event) {};

        socket.current.onclose = function (event) {
          if (event.wasClean) {
          } else {
            // e.g. server process killed or network down
          }
        };

        socket.current.onopen = () => {};
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened in setting up the request that triggered an Error
        }
        console.error("Error opening WebSocket:", error);
      }
    };

    const res = setTimeout(() => {
      if (noteId) {
        openSocket();
      }
    }, 1000);

    return () => {
      clearTimeout(res);

      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, [noteId]);

  useEffect(() => {
    if (socketResponse?.status === "created") {
      setMessages([socketResponse?.object, ...messages]);
      setNewMessagesCount(newMessagesCount + 1);
    } else {
      setMessages(
        messages.map((message) =>
          message.id === socketResponse?.object?.id
            ? {
                ...message,
                ...socketResponse?.object,
                ...(socketResponse?.status === "transcribed" && {
                  transcribed: true,
                }),
              }
            : message
        )
      );
    }
  }, [socketResponse]);

  useEffect(() => {
    if (socket.current) {
      socket.current.onmessage = async (event) => {
        try {
          const message = JSON.parse(event.data);
          setSocketResponse(message.message);
        } catch (error) {
          console.error("Error while parsing socket message:", error);
        }
      };
    }
  }, [socket.current]);

  useEffect(() => {
    return () => clearInterval(recordingIntervalRef.current!);
  }, []);

  const handleSend = async () => {
    if (!inputText) return;
    setIsUploading(true);

    if (inputText.trim()) {
      const newMessage = {
        id: Date.now().toString(),
        text: inputText,
        type: "user",
      };

      try {
        await postMessage(noteId, {
          text: newMessage.text,
        });

        setInputText("");
      } catch (error) {
        console.error("Failed to upload audio:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        e.preventDefault();
        setInputText((prev) => `${prev}\n`);
      } else {
        e.preventDefault();
        handleSend();
      }
    }
  };

  const handleSendAudio = async (blob: Blob) => {
    setIsUploading(true);
    setInputText("");
    const data = new FormData();
    data.append("audio", blob, "audio.mp3");

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const url = `/api/copilot/message/?note_id=${noteId}`;

    try {
      await client.post(url, data, config);
    } catch (error) {
      console.error("Error uploading audio:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const startRecording = async () => {
    if (isPaused) {
      togglePauseRecording();
      return;
    }

    setIsRecording(true);
    setRecordingTime(0);
    audioChunksRef.current = [];

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (e) => {
      audioChunksRef.current.push(e.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp3" });

      if (!isCancel.current && audioChunksRef.current.length) {
        handleSendAudio(audioBlob);
      } else {
        isCancel.current = false;
      }
      stream.getAudioTracks()[0].stop();

      clearInterval(recordingIntervalRef.current!);
    };

    mediaRecorderRef.current.start();

    recordingIntervalRef.current = window.setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setRecordingTime(0);
    setIsRecording(false);
    setIsPaused(false);
    audioChunksRef.current = [];
  };

  const cancelRecording = () => {
    isCancel.current = true;
    setRecordingTime(0);
    setIsRecording(false);
    setIsPaused(false);
    audioChunksRef.current = [];
    mediaRecorderRef.current?.stop();
    clearInterval(recordingIntervalRef.current!);
  };

  const togglePauseRecording = () => {
    if (mediaRecorderRef.current) {
      if (isPaused) {
        mediaRecorderRef.current.resume();
        setIsPaused(false);
        setIsRecording(true);
        recordingIntervalRef.current = window.setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);
      } else {
        mediaRecorderRef.current.pause();
        setIsPaused(true);
        setIsRecording(false);
        clearInterval(recordingIntervalRef.current!);
      }
    }
  };

  const handleInputChange = (e: any) => {
    setInputText(e.target.value);
  };

  const clearCopilot = async () => {
    try {
      await clearMessages(noteId);
      fetchMessages(noteId, true);
    } catch (error) {}
  };

  const dropdownItems = [
    {
      title: "Clear Messages",
      href: "#",
      icon: <X size={20} />,
      onClick: clearCopilot,
    },
  ];

  return (
    <div className="bg-gray-100 dark:bg-gray-950 w-full ">
      <div className="flex items-center flex h-[56px] py-[8px] px-[24px] border-b-[1px] border-gray-200 dark:border-gray-600">
        {width <= 768 && (
          <ChevronLeftIcon
            onClick={onClose}
            className="h-[30px] w-[30px] cursor-pointer"
          />
        )}
        <div className="text-xl font-medium flex items-center justify-center text-sky-800">
          <div className="flex items-center justify-center bg-sky-800 dark:bg-sky-300 h-[24px] w-[24px] rounded-full">
            <StarFour
              className="text-blue-50 dark:text-sky-900"
              size={16}
              weight="fill"
            />
          </div>
          <span className="ml-2 dark:text-sky-300">Copilot</span>
        </div>
        <div className="flex items-center justify-center rounded-full ml-auto">
          <NoteDropdown items={dropdownItems} />
        </div>
      </div>
      <div id="scroll" className="flex flex-col copilot-height">
        {isLoad ? (
          <div
            className="w-full py-10 text-center flex items-center justify-center p-2"
            style={{
              height: "calc(100vh - 267px)",
            }}
          >
            <span className="loader w-5 h-5"></span>
          </div>
        ) : (
          <div
            style={{
              height: "calc(100vh - 267px)",
            }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={loadMoreMessages}
              hasMore={messagesCount > messages.length}
              loader={
                <div className="w-full text-center p-2">
                  <span className="loader w-5 h-5"></span>
                </div>
              }
              style={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
              height={"calc(100vh - 267px)"}
              inverse={true}
              scrollableTarget="scroll"
            >
              {messages.length > 1 &&
                messages[0]?.processing_status === "STARTING" && (
                  <div className="mx-2 mt-2 mb-4 border-[1px] border-sky-200 dark:border-sky-700 rounded-[6px] flex text-sm text-sky-800 bg-sky-50 dark:bg-[#0369A140] p-[17px]">
                    <div className="flex items-center justify-center bg-sky-800 dark:bg-sky-300 h-[24px] w-[24px] rounded-full">
                      <StarFour
                        className="text-blue-50 dark:text-sky-900 mx-1"
                        size={16}
                        weight="fill"
                      />
                    </div>
                    <div className="ml-2 flex items-center text-gray-800 dark:text-sky-300">
                      <div className="flex">
                        {[1, 2, 3].map((index) => (
                          <span
                            key={index}
                            className="mr-1 h-2 w-2 rounded-full bg-gray-800 dark:bg-sky-300 blinking"
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              {[...messages].map((message) =>
                message.type === "user" ? (
                  <div className="mx-2 flex" id={message.id} key={message.id}>
                    <Avatar className="border-gray-200 border-[1px] rounded-full h-8 w-8 mr-2">
                      <AvatarImage
                        src={user?.avatar_url}
                        alt={user.first_name}
                      />
                      <AvatarFallback>
                        {user?.first_name?.slice(0, 1)}
                        {user?.last_name?.slice(0, 1)}
                      </AvatarFallback>
                    </Avatar>
                    <div className="w-full mb-2">
                      <div
                        className={
                          "w-full border-[1px] rounded-[6px] flex text-sm text-gray-600 dark:text-gray-300 bg-gray-100 dark:bg-gray-800 p-[17px] " +
                          (message.processing_status === "ERROR"
                            ? "border-red-500 bg-red-50 dark:bg-[#EF444440]"
                            : "border-gray-200 dark:border-gray-500")
                        }
                      >
                        <div
                          className={
                            message.processing_status === "ERROR" ? "mt-2" : ""
                          }
                        >
                          {!message.from_audio ? (
                            <span>{message.text}</span>
                          ) : (
                            <div>
                              {message.processing_status !== "COMPLETE" && (
                                <div
                                  className={
                                    "mb-2 px-2 flex items-center justify-center w-[133px] h-[24px] rounded-full " +
                                    "bg-gray-300"
                                  }
                                >
                                  <Microphone
                                    className={"text-gray-700"}
                                    weight="fill"
                                    size={18}
                                  />
                                  <div className={"text-gray-700"}>
                                    {"Transcribing..."}
                                  </div>
                                </div>
                              )}
                              <div className="flex items-center">
                                {[1, 2, 3, 4, 5].map((index) => (
                                  <div
                                    key={index}
                                    className="waveform mr-[2px]"
                                  >
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                  </div>
                                ))}
                              </div>
                              {message.text && <span>{message.text}</span>}
                            </div>
                          )}
                        </div>
                        {message.processing_status === "ERROR" && (
                          <RetryModal message={message} />
                        )}
                      </div>
                      <div className="flex items-center mt-1 text-gray-500 text-xs">
                        <span className="mr-1">
                          {message?.processing_status !== "COMPLETE" ? (
                            <ClockCounterClockwise size={14} />
                          ) : (
                            <Check size={14} />
                          )}
                        </span>
                        {new Date(message?.created!).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    id={message.id}
                    key={message.id}
                    className="mx-2 mt-2 mb-4 border-[1px] border-sky-200 dark:border-sky-700 rounded-[6px] flex text-sm text-sky-800 bg-sky-50 dark:bg-[#0369A140] p-[17px]"
                  >
                    <div className="flex items-center justify-center bg-sky-800 dark:bg-sky-300 h-[24px] w-[24px] rounded-full">
                      <StarFour
                        className="text-blue-50 dark:text-sky-900 mx-1"
                        size={16}
                        weight="fill"
                      />
                    </div>
                    <div className="ml-2 flex items-center text-gray-800 dark:text-sky-300">
                      {message.text}
                    </div>
                  </div>
                )
              )}
            </InfiniteScroll>
          </div>
        )}
        <div className="p-[8px] border-t rounded-tl-3xl rounded-tr-3xl border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 flex flex-col items-center h-[145px]">
          {!inputText && (
            <>
              {isRecording && <div className="audio-pulse"></div>}
              <div
                onClick={() =>
                  !isUploading && !isLoad && !isLoading
                    ? isPaused || !isRecording
                      ? startRecording()
                      : togglePauseRecording()
                    : null
                }
                className={
                  (isRecording
                    ? "bg-sky-800"
                    : isPaused
                    ? "bg-gray-600"
                    : "bg-gray-100 dark:bg-gray-800") +
                  " absolute cursor-pointer border-2 border-gray-400 dark:border-gray-600 flex items-center justify-center rounded-full h-[60px] w-[60px]"
                }
              >
                <Microphone
                  weight="fill"
                  className={
                    isRecording || isPaused
                      ? "text-gray-100"
                      : "text-gray-700 dark:text-gray-100"
                  }
                  size={32}
                />
              </div>
            </>
          )}
          {(isPaused || isRecording) && (
            <div className="w-full mt-auto flex items-center justify-between">
              <Button
                disabled={isUploading || isLoad || isLoading}
                onClick={cancelRecording}
                className="p-2 bg-red-50 h-[36px] w-[36px] rounded-full hover:bg-red-100"
              >
                <X className="text-red-700" size={32} />
              </Button>

              <div className="text-gray-500 font-semibold text-lg">
                {isPaused ? "Paused" : formatTime(recordingTime)}
              </div>

              <Button
                disabled={isUploading || isLoad || isLoading}
                onClick={stopRecording}
                className="h-[36px] w-[36px] mt-auto bg-sky-800 p-2 rounded-full hover:bg-sky-700"
              >
                <PaperPlaneRight
                  className="text-gray-50"
                  size={32}
                  weight="fill"
                />
              </Button>
            </div>
          )}
          {!isRecording && !isPaused && (
            <div
              className={
                "flex mt-auto  w-full " + (inputText ? "h-[145px]" : "h-[36px]")
              }
            >
              <Textarea
                disabled={isUploading || isLoad || isLoading}
                rows={1}
                placeholder="Type a message..."
                value={inputText}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="border-0 bg-gray-50 dark:bg-gray-800 min-h-10"
                style={{ resize: "none" }}
              />
              <Button
                disabled={isUploading || isLoad || isLoading}
                onClick={handleSend}
                className="h-[36px] w-[36px] mt-auto bg-sky-800 p-2 rounded-full mr-2 hover:bg-gray-700"
              >
                <PaperPlaneRight
                  className="text-gray-50"
                  size={32}
                  weight="fill"
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Copilot;
