import { useForm } from "react-hook-form";
import { debounce, pick, truncate } from "lodash";
import useNoteStore from "../utils/NoteStore";
import { Button } from "@/components/ui/button";
import toast, { Toaster } from "react-hot-toast";
import NoteSection from "../components/NoteSection";
import { Textarea } from "@/components/ui/noteTextarea";
import { useEffect, useRef, useState, useCallback } from "react";
import NoteDropdown from "../components/NoteDropdown";
import ConfirmationModal from "@/components/ConfirmationModal";
import SectionModal from "@/components/SectionModal";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { client } from "../utils/AxiosClient";
import {
  getNoteById,
  updateNoteFieldById,
  signNoteById,
  sectionConfigByNoteId,
  updateConfigByNoteId,
} from "../utils/Services";
import { Input } from "@/components/ui/input";
import NoteSectionPending from "../components/NoteSectionPending";
import { ChevronLeftIcon, CheckBadgeIcon } from "@heroicons/react/20/solid";
import {
  StarFour,
  PenNib,
  Copy,
  TrashSimple,
  Archive,
} from "@phosphor-icons/react";
import Copilot from "./Copilot";
import { INote, ISectionConfig } from "@/utils/Types";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { TooltipProvider } from "@/components/ui/tooltip";
import { NOTE } from "@/utils/constants";

const SOCKET_AUTH_URL = import.meta.env.VITE_SOCKET_AUTH_URL as string;
const SOCKET_URL = import.meta.env.VITE_SOCKET_URL as string;

interface MedicalNote {
  [key: string]: string | object | object[];
}

interface NoteType {
  [key: string]: string;
}
interface OutletContextType {
  handleDeleteNote: Function;
  handleArchiveNote: Function;
}

interface MedicalEntry {
  name: string;
  details: string;
  note?: string;
}

const NoteTypes: NoteType = {
  id: "string",
  mrn: "string",
  fin: "string",
  first_name: "string",
  last_name: "string",
  date_of_birth: "string",
  room_num: "string",
  chief_complaint: "string",
  text_instruction: "string",
  comments: "string",
  scratchpad: "string",
  additional_note: "string",
  age: "string",
  sex: "string",
  gender: "string",
  history_present_illness: "string",
  review_of_systems: "string",
  past_medical_history: "string",
  social_history: "string",
  family_history: "string",
  medications: "string",
  allergies: "string",
  physical_exam: "string",
  diagnostics: "string",
  differential_diagnosis: "string",
  medical_decision_making: "string",
  consults: "string",
  procedures: "array",
  name: "string",
  details: "string",
  note: "string",
  critical_care: "object",
  snippets: "array",
  assessment: "string",
  icd10_code: "string",
  condition: "string",
  disposition: "object",
  type: "string",
  admission_status: "string",
  updates: "array",
};
const SOCKET_STATUS_CONNECTED = "Connected";
const SOCKET_STATUS_UPDATED = "updated";
const SOCKET_STATUS_USER_UPDATED = "user_updated";

const Note: React.FC = () => {
  const newkey = useRef("");
  const canUpdate = useRef(false);
  const [canAnim, setCanAnim] = useState(false);
  const [confLen, setConfLen] = useState(0);
  const [textkey, setTextkey] = useState("");
  const { notes, updateNote } = useNoteStore();
  const [note, setNote] = useState({} as INote);
  const [modalOpen, setModalOpen] = useState("");
  const [isFading, setIsFading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [arr, setArr] = useState(Array<MedicalEntry>);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conf, setConf] = useState({} as ISectionConfig);
  const [isCopilotOpen, setIsCopilotOpen] = useState(false);
  const [isNoteMenuOpen, setIsNoteMenuOpen] = useState(false);
  const [socket, setSocket] = useState<WebSocket | null>(null);

  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const { handleDeleteNote, handleArchiveNote }: OutletContextType =
    useOutletContext();

  const { noteId = "", folder = "" } = useParams<{
    folder: string;
    noteId: string;
  }>();

  const updatedNote = notes.find((note) => note.id === noteId);

  const values = note;

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<INote>({ values });

  const autoSave = (value: INote) => {
    if (!canUpdate.current) {
      canUpdate.current = true;
      return;
    }

    updateNoteFieldById("notes", value.id, {
      // first_name: value.first_name,
      // date_of_birth: value.date_of_birth,
      // mrn: value.mrn ? value.mrn : null,
      // fin: value.fin ? value.fin : null,
      room_num: value.room_num,
      additional_note: value.additional_note,
      scratchpad: value.scratchpad,
      comments: value.comments,
      medical_note: value.medical_note,
    });

    if (arr.length) {
      fetchNote(noteId, false);
    }
  };

  const debounceFn = useCallback(debounce(autoSave, 1000), []);

  useEffect(() => {
    const subscription = watch((value) => {
      // @ts-ignore
      debounceFn(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const fetchNote = async (noteId: string, loading = true) => {
    if (loading) setIsLoading(true);
    try {
      const response = await getNoteById("notes", noteId);
      setNote(response);
      updateNote(response);
      canUpdate.current = false;
    } catch (error) {
      console.error(error);
    }
    if (loading) setIsLoading(false);
  };

  const fetchConfig = async (noteId: string) => {
    try {
      const response = await sectionConfigByNoteId(noteId);
      setConf(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    canUpdate.current = false;

    setArr([]);
    const res = setTimeout(() => {
      fetchNote(noteId);
      fetchConfig(noteId);
    }, 500);

    return () => {
      clearTimeout(res);
    };
  }, [noteId]);

  useEffect(() => {
    const openSocket = async () => {
      try {
        const {
          data: { uuid: uuidToken },
        } = await client.get(SOCKET_AUTH_URL);

        const newSocket = new WebSocket(`${SOCKET_URL}?uuid=${uuidToken}`);

        newSocket.onerror = function (event) {
          console.error("WebSocket error observed:", event);
        };

        newSocket.onclose = function (event) {
          if (event.wasClean) {
          } else {
            // e.g. server process killed or network down
          }
        };

        newSocket.onopen = () => {
          setSocket(newSocket);
        };
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
        console.error("Error opening WebSocket:", error);
      }
    };

    if (!socket) {
      openSocket();
    }

    // Clean up the socket connection when the component unmounts
    return () => {
      if (socket) {
        socket.close();
      }
      // setSocketConnected(false);
    };
  }, [socket]);

  // Listen for messages on socket connection
  useEffect(() => {
    if (socket) {
      // Listen for messages from the server
      socket.onmessage = async (event) => {
        try {
          const message = JSON.parse(event.data);
          const { note_id, status } = message.message;

          if (status === SOCKET_STATUS_CONNECTED) {
            return;
          }

          if (status === SOCKET_STATUS_UPDATED) {
            const note = await getNoteById("notes", note_id);
            canUpdate.current = false;

            setNote(note);
            setCanAnim(true);

            setTimeout(() => {
              setCanAnim(false);
            }, 800);
          } else if (status === SOCKET_STATUS_USER_UPDATED) {
            const note = await getNoteById("notes", note_id);
            canUpdate.current = false;

            setNote(note);
          }
        } catch (error) {
          console.error("Error while parsing socket message:", error);
        }
      };
    }
  }, [socket]);

  const signNote = async () => {
    try {
      await signNoteById("notes", noteId);
    } catch (error) {
      //
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleOpenModal = (confirmAction: string) => {
    setIsModalOpen(true);
    setModalOpen(confirmAction);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCopyNote = () => {
    const plainText = jsonToPlainText(
      pick(updatedNote, [
        "additional_note",
        "room_num",
        "chief_complaint",
        "comments",
        "medical_note",
        "scratchpad",
      ])
    );

    navigator.clipboard.writeText(plainText);

    toast.success("Copied!", {
      duration: 1000,
      position: "top-center",
    });
  };

  function jsonToPlainText(jsonData: any, indent = 0) {
    const indentSpace = "  ".repeat(indent);
    let plainText = "";

    if (typeof jsonData === "object" && !Array.isArray(jsonData)) {
      for (const key in jsonData) {
        if (jsonData.hasOwnProperty(key)) {
          const value = jsonData[key];
          if (typeof value === "object" && value !== null && value !== "") {
            plainText +=
              `${indentSpace}${NOTE[key]}:\n` +
              jsonToPlainText(value, indent + 1);
          } else if (value !== null && value !== "") {
            plainText += `${indentSpace}${NOTE[key]}: ${value}\n`;
          }
        }
      }
    } else if (Array.isArray(jsonData)) {
      jsonData.forEach((item) => {
        if (typeof item === "object" && item !== null && item !== "") {
          plainText += jsonToPlainText(item, indent + 1);
        } else if (item !== null && item !== "") {
          plainText += `${indentSpace}- ${item}\n`;
        }
      });
    } else {
      plainText += `${indentSpace}${jsonData}\n`;
    }

    return plainText;
  }

  const handleCopy = (content: string) => {
    navigator.clipboard.writeText(content || "");

    toast.success("Copied!", {
      duration: 1000,
      position: "top-center",
    });
  };

  const handleToggleNoteMenu = () => {
    setIsNoteMenuOpen(!isNoteMenuOpen);
  };

  const archiveNote = async () => {
    setIsFading(true);
    setTimeout(() => {
      handleArchiveNote(noteId);
      setIsFading(false);
    }, 250);
    setIsModalOpen(false);
  };

  const deleteNote = async () => {
    setIsFading(true);
    setTimeout(() => {
      handleDeleteNote(noteId);
      setIsFading(false);
    }, 250);
    setIsModalOpen(false);
  };

  const dropdownItems = [
    ...(width <= 768
      ? [
          {
            title: "Copy Note",
            href: "#",
            icon: <Copy size={20} />,
            onClick: handleCopyNote,
          },
        ]
      : []),
    ...(!note?.is_signed && width <= 768
      ? [
          {
            title: "Sign Note",
            icon: <PenNib size={20} />,
            href: "#",
            onClick: () => handleOpenModal("sign"),
          },
        ]
      : []),
    ...(!note?.archived
      ? [
          {
            title: "Archive Note",
            icon: <Archive size={20} />,
            href: "#",
            onClick: () => handleOpenModal("archive"),
          },
        ]
      : []),
    {
      title: "Delete Note",
      href: "#",
      icon: <TrashSimple size={20} />,
      onClick: () => handleOpenModal("delete"),
    },
  ];

  // const remove = async (key: string, index: Number) => {
  //   const updatedArray = note.medical_note[key].splice(index, 1);
  //   console.log("key: ", key, updatedArray, index);
  //   setArr([...updatedArray]);
  //   newkey.current = key;
  // };

  const add = async (key: string) => {
    // @ts-ignore
    setArr([...note?.medical_note[key], { name: "", details: "", note: "" }]);
    newkey.current = key;
  };

  useEffect(() => {
    const newNote: MedicalNote = {};
    newNote[newkey.current] = arr;
    setNote({ ...note, medical_note: { ...note.medical_note, ...newNote } });
  }, [arr]);

  const addSection = async (key: string) => {
    const section = {} as ISectionConfig;
    section[key] = true;
    const updatedConf = { ...conf, ...section };
    setConf({ ...conf, ...section });

    try {
      await updateConfigByNoteId(noteId, { section_config: updatedConf });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteSection = async (key: string) => {
    const section = {} as ISectionConfig;
    section[key] = false;
    const updatedConf = { ...conf, ...section };
    setConf({ ...conf, ...section });

    try {
      await updateConfigByNoteId(noteId, { section_config: updatedConf });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const configCount = Object.fromEntries(
      Object.entries(conf).filter(([key, value]) => !value)
    );
    setConfLen(Object.keys(configCount).length);
  }, [conf]);

  return (
    <>
      <TooltipProvider delayDuration={0}>
        <ResizablePanelGroup direction="horizontal">
          {!!note && (
            <div className="flex w-full">
              {(width <= 768 ? !isCopilotOpen : true) && (
                <ResizablePanel
                  order={3}
                  id="panel-3"
                  defaultSize={62}
                  collapsedSize={50}
                  collapsible={true}
                  minSize={50}
                  maxSize={90}
                  onResize={(col) => {
                    setTextkey(col.toString());
                  }}
                >
                  <div
                    id="top-note-div"
                    className={
                      " bg-gray-50 dark:bg-[#37415159] " +
                      (width <= 768 ? "w-full" : "w-full")
                    }
                  >
                    {width <= 768 && (
                      <div
                        onClick={() => setIsCopilotOpen(true)}
                        className="z-10 flex items-center justify-center absolute bottom-10 right-5 bg-sky-800 h-[40px] w-[40px] rounded-full"
                      >
                        <StarFour
                          className="text-blue-50"
                          size={16}
                          weight="fill"
                        />
                      </div>
                    )}
                    <div id="note-editor-div">
                      <div
                        id="note-editor-header"
                        className="flex h-[56px] py-[8px] px-[24px] border-b-[1px] border-x-[1px] border-gray-200 dark:border-gray-600"
                      >
                        <div className="flex items-center justify-center">
                          <ChevronLeftIcon
                            onClick={() => navigate(`/dashboard/notes`)}
                            className="h-[30px] w-[30px] cursor-pointer"
                          />
                          <span className="font-semibold text-xl mr-2 ">
                            {note?.chief_complaint
                              ? truncate(note?.chief_complaint, { length: 15 })
                              : "Untitled Note"}
                          </span>
                          <Input
                            placeholder="Add Room"
                            className="w-[123px] h-[32px] rounded-full dark:text-gray-200 text-base border-gray-300 dark:border-gray-600"
                            {...register("room_num")}
                          />
                        </div>
                        <div className="flex items-center justify-center ml-auto">
                          {width >= 768 && (
                            <div className="flex items-center justify-center">
                              <Copy
                                className="mr-2 cursor-pointer opacity-100 hover:opacity-50"
                                onClick={handleCopyNote}
                                size={20}
                              />
                              {note?.is_signed ? (
                                <div className="text-base font-medium flex items-center justify-center h-[32px] w-[93px] rounded-full bg-green-600 dark:bg-[#16A34A40] text-gray-100 dark:text-green-400">
                                  <CheckBadgeIcon className="text-green-200 dark:text-green-400 h-5 w-5 mr-1" />
                                  <span>Signed</span>
                                </div>
                              ) : (
                                <Button
                                  onClick={() => handleOpenModal("sign")}
                                  className="text-base font-medium flex items-center justify-center h-[32px] w-[93px] rounded-full bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-400"
                                >
                                  <PenNib
                                    size={22}
                                    weight="fill"
                                    className="mr-1"
                                  />
                                  <div>Sign</div>
                                </Button>
                              )}
                            </div>
                          )}
                          <div
                            className="flex items-center justify-center rounded-full ml-auto"
                            onClick={handleToggleNoteMenu}
                          >
                            <NoteDropdown items={dropdownItems} />
                          </div>
                        </div>
                        <Toaster />
                      </div>
                      <div
                        className={canAnim ? "note-section" : ""}
                        style={{
                          overflow: "auto",
                          height: "calc(100dvh - 122px)",
                        }}
                      >
                        {isLoading ? (
                          <>
                            <NoteSection title="">
                              <NoteSectionPending />
                            </NoteSection>
                            <NoteSection title="">
                              <NoteSectionPending />
                            </NoteSection>
                            <NoteSection title="">
                              <NoteSectionPending />
                            </NoteSection>
                            <NoteSection title="">
                              <NoteSectionPending />
                            </NoteSection>
                          </>
                        ) : !note?.is_signed ? (
                          <>
                            {/* <NoteSection className="mt-3" title="First Name">
                            <Input
                            placeholder="Add text here"
                            className="dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                            maxLength={100}
                            {...register("first_name")}
                            />
                            </NoteSection>
                            <NoteSection title="Date of Birth">
                            <Input
                            placeholder="Add text here"
                            className="dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                            {...register("date_of_birth")}
                            />
                            </NoteSection>
                            <NoteSection title="MRN">
                            <Input
                            type="number"
                            placeholder="Add text here"
                            className="dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                            {...register("mrn")}
                            />
                            </NoteSection>
                            <NoteSection title="FIN">
                            <Input
                            type="number"
                            placeholder="Add text here"
                            className="dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                            {...register("fin")}
                            />
                            </NoteSection> */}

                            {Object.entries(note?.medical_note || {}).map(
                              ([key, value]: any) => {
                                return NoteTypes[key] === "string" &&
                                  conf[key] &&
                                  !["age", "sex", "gender"].includes(key) ? (
                                  <NoteSection
                                    key={key}
                                    title={NOTE[key]}
                                    canDelete={true}
                                    deleteSection={() => deleteSection(key)}
                                    handleCopy={() =>
                                      handleCopy(value as string)
                                    }
                                  >
                                    <Textarea
                                      id={key}
                                      placeholder="Add text here"
                                      className="overflow-hidden border-0 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                                      {...register(`medical_note.${key}`)}
                                    />
                                  </NoteSection>
                                ) : NoteTypes[key] === "array" &&
                                  conf[key] &&
                                  key !== "updates" ? (
                                  <NoteSection
                                    key={key}
                                    isAdd={true}
                                    add={() => add(key)}
                                    title={NOTE[key]}
                                    canDelete={true}
                                    deleteSection={() => deleteSection(key)}
                                  >
                                    <>
                                      {(
                                        (note.medical_note[key] as []) || []
                                      ).map((data: object, index: number) => (
                                        <div key={`${data}-${index}`}>
                                          {/* <PlusCircleIcon
                                        onClick={() => remove(key, index)}
                                        className="h-[25px] w-[25px] ml-auto hover:opacity-50 cursor-pointer"
                                        /> */}
                                          {Object.entries(data || {}).map(
                                            ([k, value], index: any) => {
                                              return (
                                                k !== "note" && (
                                                  <NoteSection
                                                    key={`${data}-${k}`}
                                                    title={NOTE[k]}
                                                    handleCopy={() =>
                                                      handleCopy(value)
                                                    }
                                                  >
                                                    <Textarea
                                                      id={`medical_note.${key}.${index}.${k}`}
                                                      className="overflow-hidden border-0 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                                                      placeholder="Add text here"
                                                      {...register(
                                                        `medical_note.${key}.${index}.${k}`
                                                      )}
                                                    />
                                                  </NoteSection>
                                                )
                                              );
                                            }
                                          )}
                                        </div>
                                      ))}
                                      {!(note.medical_note[key] as [])
                                        .length && <div>No {key} yet</div>}
                                    </>
                                  </NoteSection>
                                ) : (
                                  NoteTypes[key] === "object" &&
                                  conf[key] &&
                                  key === "critical_care" && (
                                    <NoteSection
                                      key={"critical_care"}
                                      title={NOTE[key]}
                                      canDelete={true}
                                      deleteSection={() => deleteSection(key)}
                                    >
                                      <NoteSection
                                        key={"critical_care_name"}
                                        title={NOTE["name"]}
                                        handleCopy={() =>
                                          handleCopy(value?.name as string)
                                        }
                                      >
                                        <Textarea
                                          id="critical_care_name"
                                          className="overflow-hidden border-0 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                                          key={`${key}`}
                                          {...register(
                                            `medical_note.${key}.name`
                                          )}
                                        />
                                      </NoteSection>
                                      <NoteSection
                                        key={"critical_care_details"}
                                        title={NOTE["details"]}
                                        handleCopy={() =>
                                          handleCopy(value?.details as string)
                                        }
                                      >
                                        <Textarea
                                          id="critical_care_details"
                                          className="overflow-hidden border-0 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                                          key={`${key}-details`}
                                          {...register(
                                            `medical_note.${key}.details`
                                          )}
                                        />
                                      </NoteSection>
                                      {/* <NoteSection
                                      key={"critical_care-note"}
                                      title={NOTE["note"]}
                                    >
                                      <Textarea
                                        className="dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                                        key={`${key}-note`}
                                        {...register(`medical_note.${key}.note`)}
                                      />
                                    </NoteSection> */}
                                    </NoteSection>
                                  )
                                );
                                // : (
                                //   NoteTypes[key] === "object" &&
                                //   key === "disposition" && (
                                //     <NoteSection title={NOTE[key]}>
                                //       <NoteSection title={NOTE["type"]}>
                                //         <Textarea
                                //           key={`${key}-type`}
                                //           {...register(`medical_note.${key}.type`)}
                                //         />
                                //       </NoteSection>
                                //       <NoteSection title={NOTE["details"]}>
                                //         <Textarea
                                //           key={`${key}-details`}
                                //           {...register(`medical_note.${key}.details`)}
                                //         />
                                //       </NoteSection>
                                //       <NoteSection
                                //         title={NOTE["admission_status"]}
                                //       >
                                //         <Textarea
                                //           key={`${key}-admission_status`}
                                //           {...register(
                                //             `medical_note.${key}.admission_status`
                                //           )}
                                //         />
                                //       </NoteSection>
                                //     </NoteSection>
                                //   )
                                // );
                              }
                            )}
                            {/* {conf["comments"] && (
                              <NoteSection
                                title="Comments"
                                canDelete={true}
                                deleteSection={() => deleteSection("comments")}
                                handleCopy={() => handleCopy(note.comments)}
                              >
                                <Textarea
                                  id="comments"
                                  placeholder="Add text here"
                                  className="overflow-hidden border-0 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                                  {...register("comments")}
                                />
                              </NoteSection>
                            )} */}
                            {conf["scratchpad"] && (
                              <NoteSection
                                title="Scratchpad"
                                canDelete={true}
                                deleteSection={() =>
                                  deleteSection("scratchpad")
                                }
                                handleCopy={() => handleCopy(note.scratchpad)}
                              >
                                <Textarea
                                  id="scratchpad"
                                  placeholder="Add text here"
                                  className="overflow-hidden border-0 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                                  {...register("scratchpad")}
                                />
                              </NoteSection>
                            )}
                            {conf["additional_note"] && (
                              <NoteSection
                                title="Additional Note"
                                canDelete={true}
                                deleteSection={() =>
                                  deleteSection("additional_note")
                                }
                                handleCopy={() =>
                                  handleCopy(note.additional_note)
                                }
                              >
                                <Textarea
                                  id="additional_note"
                                  placeholder="Add text here"
                                  className="overflow-hidden border-0 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background"
                                  {...register("additional_note")}
                                />
                              </NoteSection>
                            )}
                          </>
                        ) : (
                          <>
                            {note.first_name && (
                              <NoteSection className="mt-3" title="First Name">
                                <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                  {note.first_name}
                                </div>
                              </NoteSection>
                            )}
                            {note.date_of_birth && (
                              <NoteSection title="Date of Birth">
                                <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                  {note.date_of_birth}
                                </div>
                              </NoteSection>
                            )}
                            {note.mrn && (
                              <NoteSection title="MRN">
                                <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                  {note.mrn}
                                </div>
                              </NoteSection>
                            )}
                            {note.fin && (
                              <NoteSection title="FIN">
                                <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                  {note.fin}
                                </div>
                              </NoteSection>
                            )}
                            {note.comments && (
                              <NoteSection title="Comments">
                                <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                  {note.comments}
                                </div>
                              </NoteSection>
                            )}
                            {note.scratchpad && (
                              <NoteSection title="Scratchpad">
                                <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                  {note.scratchpad}
                                </div>
                              </NoteSection>
                            )}
                            {note.additional_note && (
                              <NoteSection title="Additional Note">
                                <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                  {note.additional_note}
                                </div>
                              </NoteSection>
                            )}
                            {Object.entries(note?.medical_note || {}).map(
                              ([key, value]: any) => {
                                return NoteTypes[key] === "string" ? (
                                  value && (
                                    <NoteSection key={key} title={NOTE[key]}>
                                      <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                        {value as string}
                                      </div>
                                    </NoteSection>
                                  )
                                ) : NoteTypes[key] === "array" &&
                                  key !== "updates" ? (
                                  <NoteSection key={key} title={NOTE[key]}>
                                    <>
                                      {(
                                        (note.medical_note[key] as object[]) ||
                                        []
                                      ).map((data: object, index: number) => (
                                        <div key={`${data}-${index}`}>
                                          {Object.entries(data || {}).map(
                                            ([k, val]) => {
                                              return (
                                                val && (
                                                  <NoteSection
                                                    key={`${data}-${k}`}
                                                    title={NOTE[k]}
                                                  >
                                                    <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                                      {val}
                                                    </div>
                                                  </NoteSection>
                                                )
                                              );
                                            }
                                          )}
                                        </div>
                                      ))}
                                      {!(note.medical_note[key] as [])
                                        .length && <div>No {key} yet</div>}
                                    </>
                                  </NoteSection>
                                ) : (
                                  NoteTypes[key] === "object" &&
                                  key === "critical_care" &&
                                  (value?.name ||
                                    value?.details ||
                                    value?.note) && (
                                    <NoteSection
                                      key={"critical_care"}
                                      title={NOTE[key]}
                                    >
                                      {value?.name && (
                                        <NoteSection
                                          key={"critical_care-name"}
                                          title={NOTE["name"]}
                                        >
                                          <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                            {value?.name}
                                          </div>
                                        </NoteSection>
                                      )}
                                      {value?.details && (
                                        <NoteSection
                                          key={"critical_care-details"}
                                          title={NOTE["details"]}
                                        >
                                          <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                            {value?.details}
                                          </div>
                                        </NoteSection>
                                      )}
                                      {/* {value?.note && (
                                  <NoteSection
                                  key={"critical_care-note"}
                                    title={NOTE["note"]}
                                  >
                                  <div className="text-gray-600 dark:text-gray-200 text-base border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-background">
                                  {value?.note}
                                  </div>
                                  </NoteSection>
                                  )} */}
                                    </NoteSection>
                                  )
                                );
                              }
                            )}
                          </>
                        )}
                        {!!confLen && !note?.is_signed && (
                          <div className="p-4 w-full">
                            <SectionModal
                              conf={conf}
                              addSection={(key: string) => addSection(key)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </ResizablePanel>
              )}
              {width >= 768 && <ResizableHandle withHandle />}
              {(width <= 768 ? isCopilotOpen : true) && (
                <ResizablePanel
                  defaultSize={38}
                  minSize={35}
                  maxSize={50}
                  order={4}
                  id="panel-4"
                >
                  <Copilot
                    isLoad={isLoading}
                    onClose={() => setIsCopilotOpen(false)}
                  />
                </ResizablePanel>
              )}
            </div>
          )}
        </ResizablePanelGroup>
      </TooltipProvider>
      <ConfirmationModal
        isOpen={isModalOpen}
        heading={
          modalOpen === "delete"
            ? "Delete note"
            : modalOpen === "archive"
            ? "Archive note"
            : "Sign note"
        }
        message={
          modalOpen === "delete"
            ? "Are you sure you want to delete your note? All of your data will be permanently removed. This action cannot be undone."
            : modalOpen === "archive"
            ? "Are you sure you want to archive your note?"
            : "Are you sure you want to sign your note?"
        }
        confirmAction={
          modalOpen === "delete"
            ? "Delete"
            : modalOpen === "archive"
            ? "Archive"
            : "Sign"
        }
        onClose={handleCloseModal}
        onConfirm={
          modalOpen === "delete"
            ? deleteNote
            : modalOpen === "sign"
            ? signNote
            : archiveNote
        }
      />
    </>
  );
};

export default Note;
