import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { NOTE } from "@/utils/constants";

interface SectionModalProps {
  conf: {};
  addSection(key: string): void;
}

const SectionModal: React.FC<SectionModalProps> = ({ conf, addSection }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="default" className="h-8 w-full rounded-full">
          Add Section
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full sm:max-w-[600px] dark:bg-gray-900">
        <DialogHeader>
          <DialogTitle>Add Section</DialogTitle>
          {/* <DialogDescription>
            Make changes to your profile here. Click save when you're done.
          </DialogDescription> */}
        </DialogHeader>
        <div className="py-4 max-h-[70vh] overflow-auto">
          {Object.entries(conf || {}).map(([key, value]) => {
            return (
              !value && (
                <>
                  <div
                    onClick={() => {
                      addSection(key);
                      setIsOpen(false);
                    }}
                    className="dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 flex items-center cursor-pointer py-1 px-2"
                  >
                    {NOTE[key] as string}
                  </div>
                  <hr />
                </>
              )
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SectionModal;
