import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { User } from "@/utils/Types";

interface UserState {
  user: User;
}

const initialState: UserState = {
  user: {},
};

export const userSlice = createSlice({
  name: "user",

  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<object>) => {
      state.user = { ...state.user, ...action.payload };
    },
    removeUser: (state) => {
      state.user = {};
    },
  },
});

export const { saveUser, removeUser } = userSlice.actions;
export const getUser = (state: RootState) => state.user;
export default userSlice.reducer;
